import React, { useEffect } from 'react';
import { useTermsAndConditionsGuest } from './hooks/TermsAndConditionsGuest.hook';
import TermsAndConditionsGuestView from './views/TermsAndConditionsGuestView';
import LoadingComponent from '@/components/general/Loading/LoadingComponent';

const TermsAndConditionsGuestController = () => {
  const { isLoading, spacesData } = useTermsAndConditionsGuest();
  if (isLoading) {
    return <LoadingComponent loading={isLoading} />;
  } else {
    return <TermsAndConditionsGuestView spacesData={spacesData} />;
  }
};

export default TermsAndConditionsGuestController;
