import React from 'react';
import { FlexContainer } from '@/components/containers/FlexContainer/FlexContainer';
import { PageContainer } from '@/components/containers/PageContainer/PageContainer';
import Label from '@/components/form/Label/Label';

const OurClients = (props) => {
  const { customTitle } = props;
  const imgStyle = {
    filter: 'brightness(0.4)',
  };
  return (
    <FlexContainer
      className={'flex-column padt20 padlr40 m-padlr20 padb60 m-padb20 bgWhite'}
      justifyBetween
      alignItemsCenter
    >
      <PageContainer className={'text-center'}>
        {customTitle && (
          <Label type="Subtitle-1 text-20 bold padtb20 m-margtb20 padlr20 m-padlr0 fontGray250">
            {customTitle}
          </Label>
        )}
        <FlexContainer
          className={'m-flex-column padlr20 gap-80 m-gap-20'}
          justifyBetween
          style={imgStyle}
        >
          <FlexContainer alignItemsCenter className={'flex-column justify-center width100 padtb20'}>
            <img
              src="clients/clients-1.png"
              alt="Foto 1"
              style={{ height: '100px' }}
              className={''}
            />
          </FlexContainer>
          <FlexContainer alignItemsCenter className={'flex-column justify-center width100 padtb20'}>
            <img
              src="clients/clients-2.png"
              alt="Foto 2"
              style={{ height: '60px' }}
              className={''}
            />
          </FlexContainer>
          <FlexContainer alignItemsCenter className={'flex-column justify-center width100 padtb20'}>
            <img
              src="clients/clients-3.png"
              alt="Foto 3"
              style={{ height: '60px' }}
              className={''}
            />
          </FlexContainer>
          <FlexContainer alignItemsCenter className={'flex-column justify-center width100 padtb20'}>
            <img
              src="clients/clients-4.png"
              alt="Foto 3"
              style={{ height: '90px' }}
              className={''}
            />
          </FlexContainer>
        </FlexContainer>
      </PageContainer>
    </FlexContainer>
  );
};

export default OurClients;
