import { useQuery, useMutation } from '@tanstack/react-query';

const apiUrl = process.env.REACT_APP_API_URL;

export const fetchAllUserTypeRelationships = async () => {
  const response = await fetch(`${apiUrl}/GET/users_types_relationship`);
  if (!response.ok) {
    throw new Error('Error fetching user type relationships');
  }
  return response.json();
};

export const fetchUserTypeRelationshipById = async id => {
  const response = await fetch(`${apiUrl}/GET/user_type_relationship/${id}`);
  if (!response.ok) {
    throw new Error('Error fetching user type relationship by ID');
  }
  return response.json();
};

export const createUserTypeRelationship = async relationshipData => {
  const response = await fetch(`${apiUrl}/POST/user_type_relationship`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(relationshipData),
  });

  if (!response.ok) {
    throw new Error('Error creating user type relationship');
  }
  return response.json();
};

export const updateUserTypeRelationship = async ({ id, relationshipData }) => {
  const response = await fetch(`${apiUrl}/PUT/user_type_relationship/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(relationshipData),
  });
  if (!response.ok) {
    throw new Error('Error updating user type relationship');
  }
  return response.json();
};

export const deleteUserTypeRelationship = async id => {
  const response = await fetch(`${apiUrl}/PUT/delete_type_relationship_credit/${id}`, {
    method: 'PUT',
  });

  if (!response.ok) {
    throw new Error('Error deleting user type relationship');
  }
  return response.json();
};

export const deleteUserTypeRelationshipsSeveral = async relationshipIds => {
  const response = await fetch(`${apiUrl}/PUT/delete_type_relationship_credit_several`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ relationshipIds }),
  });

  if (!response.ok) {
    throw new Error('Error deleting multiple user type relationships');
  }
  return response.json();
};

export const enabledUserTypeRelationshipsSeveral = async relationshipIds => {
  const response = await fetch(`${apiUrl}/PUT/enabled_type_relationship_credit_several`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ relationshipIds }),
  });

  if (!response.ok) {
    throw new Error('Error enabling multiple user type relationships');
  }
  return response.json();
};


export const disabledUserTypeRelationshipsSeveral = async relationshipIds => {
  const response = await fetch(`${apiUrl}/PUT/disabled_type_relationship_credit_several`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ relationshipIds }),
  });

  if (!response.ok) {
    throw new Error('Error disabling multiple user type relationships');
  }
  return response.json();
};

export const useFetchAllUserTypeRelationships = () => {
  return useQuery({
    queryKey: ['userTypeRelationships'], 
    queryFn: fetchAllUserTypeRelationships,
  });
};

export const useFetchUserTypeRelationshipById = id => {
  return useQuery({
    queryKey: ['userTypeRelationship', id], 
    queryFn: () => fetchUserTypeRelationshipById(id), 
    enabled: !!id,
  });
};

export const useCreateUserTypeRelationship = () => {
  return useMutation({
    mutationFn: createUserTypeRelationship, 
    onSuccess: () => {
      console.log('User type relationship created successfully'); 
    },
    onError: error => {
      console.error('Error creating user type relationship:', error); 
    },
  });
};

export const useUpdateUserTypeRelationship = () => {
  return useMutation({
    mutationFn: updateUserTypeRelationship, 
    onSuccess: () => {
      console.log('User type relationship updated successfully'); 
    },
    onError: error => {
      console.error('Error updating user type relationship:', error); 
    },
  });
};

export const useDeleteUserTypeRelationship = () => {
  return useMutation({
    mutationFn: deleteUserTypeRelationship, 
    onSuccess: () => {
      console.log('User type relationship deleted successfully');
    },
    onError: error => {
      console.error('Error deleting user type relationship:', error); 
    },
  });
};

export const useDeleteUserTypeRelationshipsSeveral = () => {
  return useMutation({
    mutationFn: deleteUserTypeRelationshipsSeveral, 
    onSuccess: () => {
      console.log('Multiple user type relationships deleted successfully'); 
    },
    onError: error => {
      console.error('Error deleting multiple user type relationships:', error); 
    },
  });
};

export const useEnabledUserTypeRelationshipsSeveral = () => {
  return useMutation({
    mutationFn: enabledUserTypeRelationshipsSeveral, 
    onSuccess: () => {
      console.log('Multiple user type relationships enabled successfully'); 
    },
    onError: error => {
      console.error('Error enabling multiple user type relationships:', error); 
    },
  });
};

export const useDisabledUserTypeRelationshipsSeveral = () => {
  return useMutation({
    mutationFn: disabledUserTypeRelationshipsSeveral,
    onSuccess: () => {
      console.log('Multiple user type relationships disabled successfully'); 
    },
    onError: error => {
      console.error('Error disabling multiple user type relationships:', error); 
    },
  });
};
