import * as Yup from 'yup';

export const getUserValidationSchema = () => {
  return Yup.object({
    user_idnumber: Yup.string().required('ID Number is required'),
    user_name: Yup.string().required('User Name is required'),
    user_lastname: Yup.string().required('User Lastname is required'),
    user_email: Yup.string().required('User Email is required'),
    user_phone: Yup.string().required('User Phone is required'),
    main_image: Yup.mixed()
      .required('La imagen es requerida')
      .test('fileSize', 'El archivo es demasiado grande', value => {
        return value && value.size <= 15000000;
      })
      .test('fileType', 'El formato no es válido', value => {
        return value && ['image/jpeg', 'image/png'].includes(value.type);
      }),
    fecreg: Yup.date().required('Date is required'),
    password: Yup.string().required('Password is required'),
    enabled: Yup.number().required('Enabled status is required'),
    account_expired: Yup.number().required(
      'Account Expired status is required',
    ),
    credentials_expired: Yup.number().required(
      'Credentials status is required',
    ),
    account_locked: Yup.number().required('Account Locked status is required'),
    subtypeRelationship: Yup.object({
      relationship_id: Yup.number().required('Relationship ID is required'),
      type_id: Yup.number().required('Type ID is required'),
      profile_details: Yup.string().required('Profile Details are required'),
      contact_email: Yup.string()
        .email('Invalid email format')
        .required('Contact Email is required'),
      contact_phone: Yup.string().required('Contact Phone is required'),
      state_id: Yup.number().required('State ID is required'),
      fb_url: Yup.string().url('Invalid URL format for Facebook'),
      ig_url: Yup.string().url('Invalid URL format for Instagram'),
      ld_url: Yup.string().url('Invalid URL format for LinkedIn'),
      tk_url: Yup.string().url('Invalid URL format for TikTok'),
    }),
  });
};
