import React, { useState, useEffect } from 'react';

export const useNotFound = () => {
  const [isLoading, setIsLoading] = useState(true);
  let spacesData = [{}, {}, {}];

  useEffect(() => {
    if (spacesData.length >= 1) {
      setIsLoading(false);
    }
  }, [spacesData]);

  return {
    isLoading,
    setIsLoading,
    spacesData,
  };
};
