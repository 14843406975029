import React, { useEffect } from 'react';
import { useProductDetails } from './hooks/ProductDetails.hook';
import { useLocation } from 'react-router-dom';
import ProductDetailsView from './views/ProductDetailsView';
import LoadingComponent from '@/components/general/Loading/LoadingComponent';

const ProductDetailController = () => {
  const { isLoading, spacesData } = useProductDetails();
  const location = useLocation();
  const { spaceData } = location.state || {};
  if (isLoading) {
    return <LoadingComponent loading={isLoading} />;
  } else {
    return <ProductDetailsView spacesData={spacesData} spaceDetail={spaceData} />;
  }
};

export default ProductDetailController;
