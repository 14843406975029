import React, { useEffect } from 'react';
import { useAbout } from './hooks/About.hook';
import AboutView from './views/AboutView';
import LoadingComponent from '@/components/general/Loading/LoadingComponent';

const AboutController = () => {
  const { isLoading, spacesDataFromBackend } = useAbout();
  if (isLoading) {
    return <LoadingComponent loading={isLoading} />;
  } else {
    return <AboutView spacesDataFromBackend={spacesDataFromBackend} />;
  }
};

export default AboutController;
