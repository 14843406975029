import React, { useRef, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
import { PageContainer } from '@/components/containers/PageContainer/PageContainer';
import { FlexContainer } from '@/components/containers/FlexContainer/FlexContainer';
import Label from '@/components/form/Label/Label';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/pagination';
import './CustomerExperience.scss';

const CarouselBox = () => {
  return (
    <FlexContainer className={'sliderInfo-box flex-column'}>
      <h2 className={'fontWhite bold text-18 m-text-12'}>Lucía Washington</h2>
      <p className={'fontWhite text-14 m-text-8'}>
        "Trabajar en espacios recomendados por PopNest ha transformado mi experiencia profesional
        ¡Definitivamente una gran elección para cualquier freelancer!"
      </p>
    </FlexContainer>
  );
};

const CustomerExperienceVideo = React.forwardRef((props, ref) => {
  return (
    <video ref={ref} muted loop playsInline style={{ width: '100%' }}>
      <source src="customers/video1.mp4" type="video/mp4" />
      Tu navegador no soporta el elemento de video.
    </video>
  );
});

const CustomerExperience = () => {
  const videoRefs = useRef([React.createRef(), React.createRef(), React.createRef()]);
  const handleSlideChange = (swiper) => {
    videoRefs.current.forEach((ref) => {
      const video = ref.current;
      if (video) {
        video.pause();
      }
    });
    setTimeout(() => {
      const activeVideo = videoRefs.current[swiper.activeIndex].current;
      if (activeVideo) {
        activeVideo.play().catch((error) => {
          console.error('Error en la reproducción automática:', error);
        });
      }
    }, 100);
  };
  useEffect(() => {
    const firstVideo = videoRefs.current[0].current;
    if (firstVideo) {
      firstVideo.play().catch((error) => {
        console.error('Error en la reproducción automática del primer video:', error);
      });
    }
  }, []);
  return (
    <PageContainer className={'bgWhite text-center'}>
      <FlexContainer className={'padtb40 flex-column items-align-center'}>
        <Label type="Subtitle-1 text-20 bold fontGray250 text-center">Testimonios</Label>
        <Swiper
          autoplay={{
            delay: 10500,
            disableOnInteraction: true,
          }}
          pagination={{
            clickable: true,
          }}
          onSlideChange={handleSlideChange}
          modules={[Autoplay, Pagination]}
          spaceBetween={0}
          slidesPerView={1}
          className="myCustomerExperienceCarousel grayShadow16 border-rad100px marglr40 m-marglr20 margtb40 m-margtb20 format58 m-format100"
        >
          <SwiperSlide>
            <CustomerExperienceVideo ref={videoRefs.current[0]} />
            <CarouselBox />
          </SwiperSlide>
          <SwiperSlide>
            <CustomerExperienceVideo ref={videoRefs.current[1]} />
            <CarouselBox />
          </SwiperSlide>
          <SwiperSlide>
            <CustomerExperienceVideo ref={videoRefs.current[2]} />
            <CarouselBox />
          </SwiperSlide>
        </Swiper>
      </FlexContainer>
    </PageContainer>
  );
};

export default CustomerExperience;
