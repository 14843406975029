import React, { useEffect } from 'react';
import { useTermsAndConditions } from './hooks/TermsAndConditions.hook';
import TermsAndConditionsView from './views/TermsAndConditionsView';
import LoadingComponent from '@/components/general/Loading/LoadingComponent';

const TermsAndConditionsController = () => {
  const { isLoading, spacesData } = useTermsAndConditions();
  if (isLoading) {
    return <LoadingComponent loading={isLoading} />;
  } else {
    return <TermsAndConditionsView spacesData={spacesData} />;
  }
};

export default TermsAndConditionsController;
