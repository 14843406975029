import { useQuery, useMutation, keepPreviousData } from '@tanstack/react-query';

const apiUrl = process.env.REACT_APP_API_URL;

export const fetchAllAdditionalGuests = async (page = 2) => {
  const response = await fetch(`${apiUrl}/GET/additional_guests`);
  if (!response.ok) {
    throw new Error('Error fetching additional guests');
  }
  return response.json();
};

export const fetchAdditionalGuestById = async (id) => {
  const response = await fetch(`${apiUrl}/GET/additional_guests/${id}`);
  if (!response.ok) {
    throw new Error('Error fetching additional guest by ID');
  }
  return response.json();
};

export const createAdditionalGuest = async (guestData) => {
  const response = await fetch(`${apiUrl}/POST/additional_guests`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(guestData),
  });

  if (!response.ok) {
    throw new Error('Error creating additional guest');
  }
  return response.json();
};

export const updateAdditionalGuest = async ({ id, guestData }) => {
  const response = await fetch(`${apiUrl}/PUT/additional_guests/${id}`, {
    method: 'put',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(guestData),
  });
  if (!response.ok) {
    throw new Error('Error creating additional guest');
  }
  return response.json();
};

export const deleteAdditionalGuest = async (id) => {
  const response = await fetch(`${apiUrl}/PUT/delete_additional_guests/${id}`, {
    method: 'PUT',
  });

  if (!response.ok) {
    throw new Error('Error deleting additional guest');
  }
  return response.json();
};

export const deleteAdditionalGuestsSeveral = async (guestIds) => {
  const response = await fetch(`${apiUrl}/PUT/delete_additional_guests_several`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ guestIds }),
  });

  if (!response.ok) {
    throw new Error('Error deleting multiple additional guests');
  }
  return response.json();
};

export const enabledAdditionalGuestsSeveral = async (guestIds) => {
  const response = await fetch(`${apiUrl}/PUT/enabled_additional_guests_several`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ guestIds }),
  });

  if (!response.ok) {
    throw new Error('Error enabling multiple additional guests');
  }
  return response.json();
};

export const disabledAdditionalGuestsSeveral = async (guestIds) => {
  const response = await fetch(`${apiUrl}/PUT/disabled_additional_guests_several`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ guestIds }),
  });

  if (!response.ok) {
    throw new Error('Error disabling multiple additional guests');
  }
  return response.json();
};

export const useFetchAllAdditionalGuests = () => {
  return useQuery({
    queryKey: ['additionalGuests'],
    queryFn: fetchAllAdditionalGuests,
  });
};

export const useFetchAdditionalGuestById = id => {
  return useQuery({
    queryKey: ['additionalGuest', id],
    queryFn: () => fetchAdditionalGuestById(id),
    enabled: !!id,
  });
};

export const useCreateAdditionalGuest = () => {
  return useMutation({
    mutationFn: createAdditionalGuest,
    onSuccess: () => {
      console.log('Guest created successfully');
    },
    onError: error => {
      console.error('Error creating additional guest:', error);
    },
  });
};

export const useUpdateAdditionalGuest = () => {
  return useMutation({
    mutationFn: updateAdditionalGuest,
    onSuccess: () => {
      console.log('Guest updated successfully');
    },
    onError: error => {
      console.error('Error updating additional guest:', error);
    },
  });
};

export const useDeleteAdditionalGuest = () => {
  return useMutation({
    mutationFn: deleteAdditionalGuest,
    onSuccess: () => {
      console.log('Guest deleted successfully');
    },
    onError: error => {
      console.error('Error deleting additional guest:', error);
    },
  });
};

export const useDeleteAdditionalGuestsSeveral = () => {
  return useMutation({
    mutationFn: deleteAdditionalGuestsSeveral,
    onSuccess: () => {
      console.log('Multiple guests deleted successfully');
    },
    onError: error => {
      console.error('Error deleting multiple additional guests:', error);
    },
  });
};

export const useEnabledAdditionalGuestsSeveral = () => {
  return useMutation({
    mutationFn: enabledAdditionalGuestsSeveral,
    onSuccess: () => {
      console.log('Multiple guests enabled successfully');
    },
    onError: error => {
      console.error('Error enabling multiple additional guests:', error);
    },
  });
};

export const useDisabledAdditionalGuestsSeveral = () => {
  return useMutation({
    mutationFn: disabledAdditionalGuestsSeveral,
    onSuccess: () => {
      console.log('Multiple guests disabled successfully');
    },
    onError: error => {
      console.error('Error disabling multiple additional guests:', error);
    },
  });
};
