import AppRoutes from '@/router/appRoutes';
import '@/App.css';
import '@/style/main.scss';
import Footer from '@/components/general/Footer/Footer';
import Header from '@/components/general/Header/Header';

function App() {
  return (
    <div>
      <Header />
      <AppRoutes />
      <Footer />
    </div>
  );
}

export default App;
