import React, { useState } from 'react';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { setUser } from '@/redux/actions/userActions';
import { FlexContainer } from '@/components/containers/FlexContainer/FlexContainer';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { TextField } from '@mui/material';
import {
  FacebookLoginButton,
  GoogleLoginButton,
} from 'react-social-login-buttons';
import { GoogleLogin } from '@react-oauth/google';
import { gapi } from 'gapi-script';
import FacebookLogin from '@kazion/react-facebook-login';
import Label from '@/components/form/Label/Label';

const validationSchema = Yup.object({
  email: Yup.string()
    .email('Ingrese un correo válido')
    .required('Correo electrónico requerido'),
  password: Yup.string()
    .min(8, 'La contraseña debe tener al menos 8 caracteres')
    .required('Contraseña requerida'),
});

const FormFields = () => {
  const dispatch = useDispatch();

  const handleResponse = response => {
    /** console.log(response);
    const { credential } = response; // Extrae el token del credential

    // Hacer una llamada a la API de Google para obtener información del usuario
    const userInfoResponse = await fetch('https://www.googleapis.com/oauth2/v3/userinfo', {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${credential}`, // Usa el token como Bearer token
      },
    });

    if (userInfoResponse.ok) {
      const userData = await userInfoResponse.json(); // Extrae la data del usuario
      const { name, email } = userData; // Extrae nombre y email

      // Envía los datos al estado de Redux
      dispatch(setUser({ name, email }));
    } else {
      console.error('Error al obtener información del usuario', userInfoResponse);
    }*/
    console.log(response);
    const userData = {
      name: 'Mario',
      email: 'sistemas@popnest.org',
    };
    dispatch(setUser(userData));
  };

  return (
    <Formik
      initialValues={{ email: '', password: '' }}
      validationSchema={validationSchema}
      onSubmit={values => {
        console.log(values);
      }}
    >
      {({ setFieldValue, values }) => (
        <Form>
          <FlexContainer column className={'gap-20'}>
            <Field name="email">
              {({ field, form }) => (
                <TextField
                  label="Correo electrónico"
                  type="email"
                  value={values.email}
                  onChange={e => setFieldValue('email', e.target.value)}
                  error={Boolean(form.errors.email && form.touched.email)}
                  helperText={form.touched.email && form.errors.email}
                />
              )}
            </Field>

            <Field name="password">
              {({ field, form }) => (
                <TextField
                  label="Contraseña"
                  type="password"
                  value={values.password}
                  onChange={e => setFieldValue('password', e.target.value)}
                  error={Boolean(form.errors.password && form.touched.password)}
                  helperText={form.touched.password && form.errors.password}
                />
              )}
            </Field>
          </FlexContainer>

          <FlexContainer column className={'gap-10 margt20'}>
            <FacebookLogin
              className={'bgBlue6 bold fontWhite padtb10'}
              onSuccess={handleResponse}
            />
            <GoogleLogin
              className={'format100'}
              onSuccess={credentialResponse => {
                console.log(credentialResponse);
                handleResponse(credentialResponse);
              }}
              onError={() => {
                console.log('Login Failed');
              }}
            />
            <button
              type="submit"
              className={
                'bgPurple4 bold border-rad8px borderGray050 fontWhite grayShadow16 margtb20 padlr40 padtb20 text-18'
              }
            >
              Ingresar
            </button>
          </FlexContainer>
        </Form>
      )}
    </Formik>
  );
};

const GeneralLoginForm = props => {
  const { inputValue, className } = props;
  const containerClass = className + ' flex-column border-rad16px margt4';
  return (
    <FlexContainer className={containerClass}>
      <Label
        type="Subtitle-1"
        className={'text-20 m-text-16 text-left margt40 m-margt10'}
      >
        Iniciar Sesión en Popnest
      </Label>
      <FlexContainer className={'m-flex-column width100 margt20 gap-20'}>
        <FlexContainer className={'flex-column width100 Subtitle-1'}>
          <FormFields />
        </FlexContainer>
      </FlexContainer>
    </FlexContainer>
  );
};

export default GeneralLoginForm;
