import React from 'react';
import classnames from 'classnames';
import { FlexContainer } from '@/components/containers/FlexContainer/FlexContainer';

const PageContainer = (props) => {
  const { children, className, mainContainerClass } = props;
  const containerClass = classnames(
    !mainContainerClass ? 'flex-column padlr130 padtb30 m-padtb10 m-padlr10' : mainContainerClass,
    className,
  );
  return <FlexContainer className={containerClass}>{children}</FlexContainer>;
};

export { PageContainer };
