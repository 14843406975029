import React, { useState, useEffect } from 'react';
import { FlexContainer } from '@/components/containers/FlexContainer/FlexContainer';
import SpacesList from '@/components/modules/SpacesList/SpacesList';
import SearchAccordion from '@/components/general/SearchAccordion/SearchAccordion';
import './AboutView.scss';

const About = (props) => {
  const { spacesDataFromBackend } = props;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <FlexContainer className={'flex-column width100'}>
      <SearchAccordion className={'margt70 width100'} />
      <FlexContainer className={'aboutView flex-column margt100'}>
        <SpacesList
          spacesData={spacesDataFromBackend
            ?.filter((space) => space.subtype_title === 'Coworking')
            .slice(0, 3)}
          customTitle={'Coworkings'}
          buttonTitle={'Ver más espacios'}
          isShowButton
        />
        {spacesDataFromBackend
          ?.filter((space) => space.subtype_title === 'Sala de Juntas')
          .slice(0, 3) && (
          <SpacesList
            spacesData={spacesDataFromBackend
              ?.filter((space) => space.subtype_title === 'Sala de Juntas')
              .slice(0, 3)}
            customTitle={'Sala de Juntas'}
            buttonTitle={'Ver más espacios'}
            isShowButton
          />
        )}
      </FlexContainer>
    </FlexContainer>
  );
};

export default About;
