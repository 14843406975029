import React from 'react';
import { FlexContainer } from '@/components/containers/FlexContainer/FlexContainer';
import { PageContainer } from '@/components/containers/PageContainer/PageContainer';
import Label from '@/components/form/Label/Label';
import { Link } from 'react-router-dom';
import '@fortawesome/fontawesome-free/css/all.min.css';

const Footer = (props) => {
  const {} = props;
  return (
    <PageContainer className={'bgBlue400'}>
      <FlexContainer className={'flex-column padt20 padb60'} justifyBetween alignItemsStart>
        <FlexContainer className={'m-flex-column width100 margt20'}>
          <FlexContainer className={'flex-column width100'}>
            <img src="logo.png" alt="Foto 1" style={{ width: '35%' }} className={''} />
            <FlexContainer className={'width100'}>
              <i className="fab fa-facebook-f fontWhite text-18 margl10" />
              <i className="fab fa-twitter fontWhite text-18 margl10" />
              <i className="fab fa-instagram fontWhite text-18 margl10" />
              <i className="fab fa-linkedin-in fontWhite text-18 margl10" />
            </FlexContainer>
            <p className={'fontWhite margt20 text-10 padlr8'}>
              Somos un equipo de apasionados por la innovación y el buen vivir. Ofrecemos espacios
              de trabajo únicos, con privacidad, buen diseño y sin ruido.
            </p>
          </FlexContainer>
          <FlexContainer className={'flex-column width100 padlr8'}>
            <ul
              className={'fontWhite subTitle1 text-12'}
              style={{ listStyleType: 'none', padding: 0 }}
            >
              <li className={'margb20'}>
                <Link to="/" className={'fontWhite'} style={{ textDecoration: 'none' }}>
                  Inicio
                </Link>
              </li>
              <li className={'margb20'}>
                <Link to="/about" className={'fontWhite'} style={{ textDecoration: 'none' }}>
                  Acerca de Popnest
                </Link>
              </li>
              <li className={'margb20'}>
                <Link to="/spaces" className={'fontWhite'} style={{ textDecoration: 'none' }}>
                  Espacios
                </Link>
              </li>
              <li className={'margb20'}>
                <Link to="/yourSpaces" className={'fontWhite'} style={{ textDecoration: 'none' }}>
                  Publica tu espacio
                </Link>
              </li>
            </ul>
          </FlexContainer>
          <FlexContainer className={'flex-column width100 padlr8'}>
            <ul
              className={'fontWhite subTitle1 text-12'}
              style={{ listStyleType: 'none', padding: 0 }}
            >
              <li className={'margb20'}>
                <Link to="/" className={'fontWhite'} style={{ textDecoration: 'none' }}>
                  Mi Perfil
                </Link>
              </li>
              <li className={'margb20'}>
                <Link
                  to="/termsAndConditionsHost"
                  className={'fontWhite'}
                  style={{ textDecoration: 'none' }}
                >
                  Términos y Condiciones Anfitrión
                </Link>
              </li>
              <li className={'margb20'}>
                <Link
                  to="/termsAndConditionsGuest"
                  className={'fontWhite'}
                  style={{ textDecoration: 'none' }}
                >
                  Términos y Condiciones Invitado
                </Link>
              </li>
            </ul>
          </FlexContainer>
          <FlexContainer className={'flex-column width100 padlr8'}>
            <ul
              className={'fontWhite subTitle1 text-12'}
              style={{ listStyleType: 'none', padding: 0 }}
            >
              <li className={'margb20'}>
                <Link to="/" className={'fontWhite'} style={{ textDecoration: 'none' }}>
                  Mi Perfil
                </Link>
              </li>
              <li className={'margb20'}>
                <Link
                  to="/privacyConditions"
                  className={'fontWhite'}
                  style={{ textDecoration: 'none' }}
                >
                  Política de Privacidad
                </Link>
              </li>
            </ul>
          </FlexContainer>
        </FlexContainer>
        <FlexContainer className={'width100 margt60'}>
          <Label className={'subTitle2 text-10 m-text-8 fontWhite margt8 padlr8'}>
            Copyright © 2024 Finovix Sapi de CV. Todos los derechos reservados.
          </Label>
        </FlexContainer>
      </FlexContainer>
    </PageContainer>
  );
};

export default Footer;
