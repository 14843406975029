import React, { useState, useEffect } from 'react';
import SpacesList from '@/components/modules/SpacesList/SpacesList';
import OurClients from '@/components/modules/OurClients/OurClients';
import HowItWorks from '@/components/modules/HowItWorks/HowItWorks';
import HomeSlider from '../components/HomeSlider/HomeSlider';
import HomeFilters from '../components/HomeFilters/HomeFilters';
import CustomerExperience from '../components/CustomerExperience/CustomerExperience';

const HomeView = (props) => {
  const { spacesDataFromBackend } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="App">
      <HomeSlider spacesData={spacesDataFromBackend} />
      <HomeFilters />
      <SpacesList spacesData={spacesDataFromBackend} key={1} />
      {/*<SpacesList
        spacesData={spacesDataFromBackend}
        customTitle={'Favoritos'}
        buttonTitle={'Ver más espacios'}
        isShowButton
        key={2}
      />*/}
      <OurClients customTitle={'Ellos Confían en Nosotros'} />
      <HowItWorks
        customTitle={'¿Cómo funciona nuestro servicio?'}
        buttonTitle={'Revisa Nuestras Preguntas Frecuentes'}
        isShowButton
      />
      <CustomerExperience />
    </div>
  );
};

export default HomeView;
