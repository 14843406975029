import React, { useState, useEffect } from 'react';
import { FlexContainer } from '@/components/containers/FlexContainer/FlexContainer';
import { PageContainer } from '@/components/containers/PageContainer/PageContainer';
import Label from '@/components/form/Label/Label';
import './TermsAndConditionsGuest.scss';

const TermsAndConditionsGuest = (props) => {
  const { spacesData } = props;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <PageContainer className={'flex-column margtb80 text-justify'}>
      <Label className={'text-12 m-text-10'}>
        <h1 className={'Subtitle-1 text-22'}>Términos y Condiciones de Usuario</h1>

        <p>
          FINOVIX S.A.P.I. de C.V. (en lo sucesivo POPNEST), con domicilio en SAN ÁNGEL, ÁLVARO
          OBREGÓN da a conocer por este medio el convenio donde se describen los Términos y
          Condiciones generales de uso de su página web (en adelante, la “Página”) y/o cualquier
          otra plataforma digital que exponga a tu disposición, en las cuales se pueden encontrar en
          forma enunciativa más no limitativa, información, servicios, consultas, contenidos y/o
          productos. (en adelante los “Servicios”).
        </p>

        <p>
          Este contrato describe los términos y condiciones generales (en adelante únicamente
          "TÉRMINOS Y CONDICIONES") aplicables al uso de los contenidos, productos y servicios
          ofrecidos a través del sitio{' '}
          <a href="https://www.popnest.org/" target="_blank">
            https://www.popnest.org/
          </a>{' '}
          (en adelante, "SITIO WEB"), del cual es titular FINOVIX S.A.P.I. de C.V. (en adelante,
          POPNEST). Quien desee acceder o usar el sitio o los servicios ofrecidos, podrá hacerlo
          sujetándose a los presentes TÉRMINOS Y CONDICIONES, así como a políticas y principios
          incorporados a este documento.
        </p>

        <p>
          En todo caso, cualquier persona que no acepte los presentes términos y condiciones, deberá
          abstenerse de utilizar el SITIO WEB y/o adquirir los productos y servicios que en su caso
          sean ofrecidos.
        </p>

        <h3 className={'Subtitle-2 margt40'}>I. DEL OBJETO.</h3>

        <p>
          El objeto de los presentes TÉRMINOS Y CONDICIONES es regular el acceso y la utilización
          del SITIO WEB, entendiendo por éste cualquier tipo de contenido, producto o servicio que
          se encuentre a disposición del público en general dentro del dominio:{' '}
          <a href="https://www.popnest.org/" target="_blank">
            https://www.popnest.org/
          </a>
        </p>

        <p>
          POPNEST se reserva la facultad de modificar en cualquier momento y sin previo aviso, la
          presentación, los contenidos, la funcionalidad, los productos y los servicios, que
          pudieran estar contenidos en el SITIO WEB; en este sentido, el USUARIO reconoce y acepta
          que POPNEST en cualquier momento podrá interrumpir, desactivar o cancelar cualquiera de
          los elementos que conforman el SITIO WEB o el acceso a los mismos.
        </p>

        <p>
          Se podrán aplicar condiciones suplementarias a determinados Servicios, como políticas para
          una actividad o una promoción particular, y dichas condiciones suplementarias se
          comunicarán en relación con los Servicios aplicables. Las condiciones suplementarias se
          establecen además de los Términos y Condiciones, y se considerarán una parte más de éstas,
          para los fines de los Servicios aplicables. Las condiciones suplementarias prevalecerán
          sobre las Condiciones en el caso de conflicto con respecto a los Servicios aplicables.
        </p>

        <p>
          El acceso al SITIO WEB por parte del USUARIO tiene carácter libre y, por regla general, es
          gratuito sin que el USUARIO tenga que proporcionar una contraprestación para poder
          disfrutar de ello, salvo en lo referente al costo de la conexión a internet suministrada
          por el proveedor de este tipo de servicios que hubiere contratado el mismo USUARIO.
        </p>

        <p>
          El SITIO WEB se encuentra dirigido exclusivamente a personas que cuenten con la mayoría de
          edad (mayores de 18 años); en este sentido, POPNEST declina cualquier responsabilidad por
          el incumplimiento de este requisito.
        </p>

        <p>
          El SITIO WEB está dirigido principalmente a USUARIOS residentes en la República Mexicana,
          por lo cual, POPNEST no asegura que el SITIO WEB cumpla total o parcialmente con la
          legislación de otros países, de forma que, si el USUARIO reside o tiene su domicilio
          establecido en otro país y decide acceder o utilizar el SITIO WEB lo hará bajo su propia
          responsabilidad y deberá asegurarse de que tal acceso y navegación cumple con la
          legislación local que le es aplicable, no asumiendo POPNEST ninguna responsabilidad que se
          pueda derivar de dicho acto.
        </p>

        <p>
          Se hace del conocimiento del USUARIO que POPNEST podrá administrar o gestionar el SITIO
          WEB de manera directa o a través de un tercero, lo cual no modifica en ningún sentido lo
          establecido en los presentes TÉRMINOS Y CONDICIONES.
        </p>

        <h3 className={'Subtitle-2 margt40'}>II. DEL USUARIO.</h3>

        <p>
          El acceso o utilización del SITIO WEB, así como de los recursos habilitados para
          interactuar entre los USUARIOS, o entre el USUARIO y POPNEST tales como medios para
          realizar publicaciones o comentarios, confiere la condición de USUARIO del SITIO WEB, por
          lo que quedará sujeto a los presentes TÉRMINOS Y CONDICIONES, así como a sus ulteriores
          modificaciones, sin perjuicio de la aplicación de la legislación aplicable, por tanto, se
          tendrán por aceptados desde el momento en el que se accede al SITIO WEB. Dada la
          relevancia de lo anterior, se recomienda al USUARIO revisar las actualizaciones que se
          realicen a los presentes TÉRMINOS Y CONDICIONES.
        </p>

        <p>
          Es responsabilidad del USUARIO utilizar el SITIO WEB de acuerdo con la forma en la que fue
          diseñado; en este sentido, queda prohibida la utilización de cualquier tipo de software
          que automatice la interacción o descarga de los contenidos o servicios proporcionados a
          través del SITIO WEB.
        </p>

        <p>
          Además, el USUARIO se compromete a utilizar la información, contenidos o servicios
          ofrecidos a través del SITIO WEB de manera lícita, sin contravenir lo dispuesto en los
          presentes TÉRMINOS Y CONDICIONES, la moral o el orden público, y se abstendrá de realizar
          cualquier acto que pueda suponer una afectación a los derechos de terceros, o perjudique
          de algún modo el funcionamiento del SITIO WEB.
        </p>

        <p>
          Así mismo, el USUARIO se compromete a proporcionar información lícita y veraz en los
          formularios habilitados en el SITIO WEB, en los cuales el USUARIO tenga que proporcionar
          ciertos datos o información para el acceso a algún contenido, producto o servicio ofrecido
          por el propio SITIO WEB. En todo caso, el USUARIO notificará de forma inmediata a POPNEST
          acerca de cualquier hecho que permita suponer el uso indebido de la información registrada
          en dichos formularios, tales como, robo, extravío, o acceso no autorizado a cuentas y/o
          contraseñas, con el fin de proceder a su inmediata cancelación.
        </p>

        <p>
          Sujeto al cumplimiento de los presentes TÉRMINOS Y CONDICIONES, los Clientes y/o USUARIOS
          crean, con su correo electrónico, una cuenta de USUARIO personal, exclusiva e
          intransferible para realizar los procesos de compra de productos o de adquisición de la
          Página. POPNEST se reserva cualquier derecho que no haya sido expresamente otorgado por
          los presentes TÉRMINOS Y CONDICIONES.
        </p>
        <p>
          El uso de la Cuenta de USUARIO es personal e intransferible, por lo cual los Clientes y/o
          USUARIOS no se encuentran facultados para ceder los datos de validación para el acceso a
          su perfil. En caso de olvido de los datos de validación o de usurpación de éstos, es
          obligación del USUARIO informar a POPNEST mediante el envío de un correo electrónico a la
          cuenta de <a href="mailto:info@popnest.org">info@popnest.org</a>
        </p>
        <p>
          De igual manera, el USUARIO acepta el aviso de privacidad y términos y condiciones del
          SITIO WEB, al ponerse en contacto con POPNEST a través de medios electrónicos, como
          llamada telefónica, WhatsApp o correo electrónico.
        </p>

        <p>
          Es de la entera responsabilidad del USUARIO mantener de forma confidencial y en un lugar
          seguro su USUARIO y contraseña para acceder a su perfil en el portal de POPNEST, por lo
          que, mediante el presente instrumento, el USUARIO deslinda a POPNEST de toda
          responsabilidad por la pérdida o daño alguno, sin importar su alcance o naturaleza, que
          resulte del acceso no autorizado al perfil del Cliente o USUARIO.
        </p>

        <p>
          Sin perjuicio de otras medidas, POPNEST podrá advertir, suspender en forma temporal o
          inhabilitar definitivamente la Cuenta de un USUARIO o una publicación, aplicar una sanción
          que impacte negativamente en la reputación de un USUARIO, iniciar las acciones que estime
          pertinentes y/o suspender la prestación de sus Servicios si:
        </p>
        <ul>
          <li>
            se quebrantara alguna ley, o cualquiera de las estipulaciones de los Términos y
            Condiciones Generales y demás políticas de POPNEST
          </li>
          <li>si incumpliera sus compromisos como USUARIO</li>
          <li>
            si se incurriera, a criterio de POPNEST, en conductas o actos dolosos o fraudulentos
          </li>
          <li>
            no pudiera verificarse la identidad del USUARIO o cualquier información proporcionada
            por el mismo fuere errónea
          </li>
          <li>
            POPNEST entendiera que las publicaciones u otras acciones pueden ser causa de
            responsabilidad para el USUARIO que las publicó
          </li>
        </ul>

        <p>
          POPNEST se reserva el derecho de retirar todos aquellos comentarios y aportaciones que
          vulneren la ley, el respeto a la dignidad de la persona, que sean discriminatorios, y/o
          atenten contra los derechos de terceros o el orden público, o bien, que a su juicio no
          resulten adecuados para su publicación.
        </p>

        <p>
          En cualquier caso, POPNEST no será responsable de las opiniones vertidas por los USUARIOS
          a través de comentarios o publicaciones que estos realicen.
        </p>

        <p>
          El sólo acceso al SITIO WEB no supone el establecimiento de ningún tipo de relación entre
          POPNEST y el USUARIO.
        </p>

        <p>
          Al tratarse de un SITIO WEB dirigido exclusivamente a personas que cuenten con la mayoría
          de edad, el USUARIO manifiesta ser mayor de edad y disponer de la capacidad jurídica
          necesaria para sujetarse a los presentes TÉRMINOS Y CONDICIONES.
        </p>

        <h3 className={'Subtitle-2 margt40'}>III. DEL ACCESO Y NAVEGACIÓN EN EL SITIO WEB.</h3>

        <p>
          POPNEST no garantiza de ningún modo la continuidad y disponibilidad de los contenidos,
          productos o servicios ofrecidos a través del SITIO WEB, no obstante, POPNEST llevará a
          cabo las acciones que de acuerdo con sus posibilidades le permitan mantener el buen
          funcionamiento del SITIO WEB, sin que esto suponga alguna responsabilidad de parte de
          POPNEST.
        </p>

        <p>
          De igual forma, POPNEST no será responsable ni garantiza que el contenido o software al
          que pueda accederse a través del SITIO WEB se encuentre libre de errores, software
          malicioso, o que pueda causar algún daño a nivel de software o hardware en el equipo a
          través del cual el USUARIO accede al SITIO WEB.
        </p>

        <p>
          POPNEST tampoco se hace responsable de los daños que pudiesen ocasionarse por un uso
          inadecuado del SITIO WEB. En ningún caso POPNEST será responsable por las pérdidas, daños
          o perjuicios de cualquier tipo que surjan por el sólo acceso o utilización del SITIO WEB.
        </p>

        <h3 className={'Subtitle-2 margt40'}>IV. POLÍTICA DE PRIVACIDAD Y PROTECCIÓN DE DATOS.</h3>

        <p>
          De conformidad con lo establecido en la Ley Federal de Protección de Datos Personales en
          Posesión de Particulares, POPNEST se compromete a adoptar las medidas necesarias que estén
          a su alcance para asegurar la privacidad de los datos personales recabados de forma que se
          garantice su seguridad, se evite su alteración, pérdida o tratamiento no autorizado.
        </p>

        <p>
          Además, a efecto de dar cumplimiento a lo establecido en la Ley Federal de Protección de
          Datos Personales en Posesión de Particulares, todo dato personal que sea recabado a través
          del SITIO WEB será tratado de conformidad con los principios de licitud, calidad,
          finalidad, lealtad, y responsabilidad. Todo tratamiento de datos personales quedará sujeto
          al consentimiento de POPNEST.
        </p>

        <p>
          En todo caso, la utilización de datos financieros o patrimoniales requerirá de
          autorización expresa de POPNEST (y/o sus filiales o entidades asociadas), no obstante,
          esta podrá darse a través del propio SITIO WEB utilizando los mecanismos habilitados para
          tal efecto, y en todo caso se dará la mayor diligencia y cuidado a este tipo de datos. Lo
          mismo ocurrirá en el caso de datos personales sensibles, considerando por estos aquellos
          que debido a una utilización indebida puedan dar origen a discriminación o su divulgación
          conlleve un riesgo para POPNEST.
        </p>

        <p>
          En todo momento se procurará que los datos personales contenidos en las bases de datos o
          archivos que en su caso se utilicen, sean pertinentes, correctos y actualizados para los
          fines para los cuales fueron recabados.
        </p>

        <p>
          El tratamiento de datos personales se limitará al cumplimiento de las finalidades
          previstas en el{' '}
          <a href="https://www.popnest.org/legal-aviso-de-privacidad">Aviso de Privacidad</a> el cual
          se encontrará disponible en la siguiente dirección electrónica.
        </p>

        <p>
          El SITIO WEB podrá incluir hipervínculos o enlaces que permitan acceder a páginas web de
          terceros distintos a POPNEST. De dichos sitios web dispondrán de sus propias políticas de
          privacidad y protección de datos, por lo cual POPNEST no asume ningún tipo de
          responsabilidad por los datos que sean facilitados por el USUARIO a través de cualquier
          SITIO WEB distinto a POPNEST.
        </p>

        <p>
          Los datos personales recogidos durante la compra se tratarán según la Política de
          Privacidad de POPNEST. Asimismo, POPNEST no se hace responsable por el uso de los datos
          personales del USUARIO por parte de las compañías de paquetería y plataformas de pago, ya
          que son entidades ajenas a POPNEST.
        </p>

        <p>
          POPNEST se reserva el derecho a modificar su Política de Privacidad, de acuerdo con sus
          necesidades o derivado de algún cambio en la legislación. El acceso o utilización del
          SITIO WEB después de dichos cambios, implicará la aceptación de estos cambios.
        </p>

        <p>
          Por otra parte, el acceso al SITIO WEB puede implicar la utilización de cookies, las
          cuales son pequeñas cantidades de información que se almacenan en el navegador utilizado
          por el USUARIO. Las cookies facilitan la navegación, la hacen más amigable, y no dañan el
          dispositivo de navegación; para ello, pueden recabar información para ingresar al SITIO
          WEB, almacenar las preferencias del USUARIO, así como la interacción que este tenga con el
          SITIO WEB, como por ejemplo: la fecha y hora en la que se accede al SITIO WEB, el tiempo
          que se ha hecho uso de este, los sitios visitados antes y después del mismo, el número de
          páginas visitadas, la dirección IP de la cual accede el USUARIO, la frecuencia de visitas,
          etc.
        </p>

        <p>
          Este tipo de información será utilizada para mejorar el SITIO WEB, detectar errores y
          posibles necesidades que el USUARIO pueda tener, lo anterior a efecto de ofrecer a los
          USUARIOS servicios y contenidos de mejor calidad. En todo caso, la información que se
          recopile será anónima y no se identificará a USUARIOS individuales.
        </p>

        <p>
          En caso de que el USUARIO no desee que se recopile este tipo de información, deberá
          deshabilitar, rechazar, restringir y/o eliminar el uso de cookies en su navegador de
          internet. Los procedimientos para realizar estas acciones pueden diferir de un navegador a
          otro; en consecuencia, se sugiere revisar las instrucciones facilitadas por el
          desarrollador del navegador. En el supuesto de que rechace el uso de cookies (total o
          parcialmente), el USUARIO podrá continuar haciendo uso del SITIO WEB, aunque podrían
          quedar deshabilitadas algunas de las funciones de este.
        </p>

        <p>
          Es posible que en el futuro estas políticas respecto a las cookies cambien o se
          actualicen, por ello es recomendable revisar las actualizaciones que se realicen a los
          presentes TÉRMINOS Y CONDICIONES, con el objetivo de estar adecuadamente informado sobre
          cómo y para qué utilizamos las cookies que se generan al ingresar o hacer uso del SITIO
          WEB.
        </p>
        <h3 className={'Subtitle-2 margt40'}>V. POLÍTICA DE ENLACES</h3>

        <p>
          El SITIO WEB puede contener enlaces, contenidos, servicios o funciones, de otros sitios de
          internet pertenecientes y/o gestionados por terceros, como por ejemplo imágenes, videos,
          comentarios, motores de búsqueda, etc.
        </p>

        <p>
          La utilización de estos enlaces, contenidos, servicios o funciones tiene por objeto
          mejorar la experiencia del USUARIO al hacer uso del SITIO WEB, sin que pueda considerarse
          una sugerencia, recomendación o invitación para hacer uso de sitios externos. POPNEST en
          ningún caso revisará o controlará el contenido de los sitios externos, de igual forma, no
          hace propios los productos, servicios, contenidos, y cualquier otro material existente en
          los referidos sitios enlazados; por lo cual, tampoco se garantizará la disponibilidad,
          exactitud, veracidad, validez o legalidad de los sitios externos a los que se pueda tener
          acceso a través del SITIO WEB. Así mismo, POPNEST no asume ninguna responsabilidad por los
          daños y perjuicios que pudieran producirse por el acceso o uso, de los contenidos,
          productos o servicios disponibles en los sitios web no gestionados por POPNEST a los que
          se pueda acceder mediante el SITIO WEB.
        </p>

        <p>
          Los USUARIOS o terceros que realicen o publiquen un enlace web desde una página web
          externa, a este SITIO WEB deberán tomar en cuenta lo siguiente:
        </p>

        <ul>
          <li>
            No se permite la reproducción (total o parcial) de los contenidos, productos o servicios
            disponibles en el SITIO WEB sin la autorización expresa de POPNEST.
          </li>
          <li>
            Tampoco se permitirán manifestaciones falsas, inexactas o incorrectas sobre el SITIO
            WEB, ni sobre sus contenidos, productos o servicios, pudiendo POPNEST restringir el
            acceso al SITIO WEB a toda aquella persona que incurra en este tipo de actos.
          </li>
        </ul>

        <p>
          El establecimiento de un enlace al SITIO WEB desde cualquier sitio externo, no implicará
          la existencia de alguna relación entre POPNEST y del SITIO WEB desde el cual se realice,
          tampoco implica el conocimiento de POPNEST sobre los contenidos, productos o servicios
          ofrecidos en los sitios externos desde los cuales se pueda acceder al SITIO WEB.
        </p>

        <h3 className={'Subtitle-2 margt40'}>VI. POLÍTICA EN MATERIA DE PROPIEDAD INTELECTUAL E INDUSTRIAL</h3>

        <p>
          POPNEST, por sí o como parte cesionaria, es titular de todos los derechos de propiedad
          intelectual e industrial del SITIO WEB, entendiendo por éste el código fuente que hace
          posible su funcionamiento, así como las imágenes, archivos de audio o video, estructuras,
          diseños y demás elementos que lo distinguen. Estos serán protegidos por la legislación
          mexicana en materia de propiedad intelectual e industrial, así como por los tratados
          internacionales aplicables. Por consiguiente, queda expresamente prohibida la
          reproducción, distribución o difusión de los contenidos del SITIO WEB, con fines
          comerciales, en cualquier soporte y por cualquier medio, sin la autorización de POPNEST.
        </p>

        <p>
          El USUARIO se compromete a respetar los derechos de propiedad intelectual e industrial de
          POPNEST; no obstante, además de poder visualizar los elementos del SITIO WEB, podrá
          imprimirlos, copiarlos o almacenarlos, siempre y cuando sea exclusivamente para su uso
          estrictamente personal.
        </p>

        <p>
          Por otro lado, el USUARIO se abstendrá de suprimir, alterar o manipular cualquier
          elemento, archivo o contenido del SITIO WEB, y por ningún motivo realizará actos
          tendientes a vulnerar la seguridad, los archivos o bases de datos que se encuentren
          protegidos, ya sea a través de un acceso restringido mediante un USUARIO y contraseña, o
          porque no cuente con los permisos para visualizarlos, editarlos o manipularlos.
        </p>

        <p>
          En caso de que el USUARIO o algún tercero consideren que cualquiera de los contenidos del
          SITIO WEB suponga una violación de los derechos de protección de la propiedad industrial o
          intelectual, deberá comunicarlo inmediatamente a través de los datos de contacto
          disponibles en el propio SITIO WEB.
        </p>

        <h3 className={'Subtitle-2 margt40'}>VII. COMUNICADOS ELECTRÓNICOS</h3>

        <p>
          El USUARIO acepta recibir comunicados electrónicos a través de WhatsApp, e-mail y
          mensajería instantánea a través del SITIO WEB por parte de POPNEST. Los avisos se
          divulgarán a través de: correo electrónico; WhatsApp y por medio de colocar avisos en la
          Página Web.
        </p>

        <p>
          El USUARIO acepta que las divulgaciones que sean enviadas mediante correo electrónico
          satisfacen los requerimientos legales.
        </p>

        <h3 className={'Subtitle-2 margt40'}>VIII. PROMOCIONES ESPECIALES</h3>

        <p>
          POPNEST podrá lanzar promociones especiales mediante las cuales se podrán ofrecer
          distintos beneficios a sus USUARIOS. La participación y aceptación de estas promociones
          corresponderá a criterios determinados exclusivamente por nosotros. En ningún momento
          POPNEST se compromete a lanzar promociones especiales en fechas específicas o derivadas de
          alguna eventualidad.
        </p>

        <p>
          Los términos y condiciones de las promociones especiales que POPNEST ofrezca se
          comunicarán cuando se publique la promoción, su vigencia, y se sujetarán a los presentes
          términos y condiciones y a las leyes mexicanas aplicables en la materia.
        </p>

        <h3 className={'Subtitle-2 margt40'}>IX. CONSENTIMIENTO</h3>

        <p>
          Manifiesta el USUARIO que la relación contractual que se puede llegar a generar por el uso
          de la Página o cualquiera de los Productos ofrecidos será directamente con POPNEST y
          consistirá en una prestación de servicios celebrada por medios electrónicos, en la cual el
          Cliente es el acreditado y POPNEST es el acreditante.
        </p>

        <p>
          En virtud de las condiciones de capacidad legal establecidas en la legislación mexicana
          vigente y de la validez de la manifestación expresa de voluntad, a través de medios
          electrónicos establecida en los artículos 89 y 90 del Código de Comercio, como USUARIO al
          momento de la creación de tu Cuenta, manifiestas expresamente tu consentimiento y contar
          con la capacidad suficiente para celebrar contratos que se pueden o pudieren llegar a
          realizar accediendo y usando la Página o los Productos de POPNEST.
        </p>

        <p>
          En este sentido, la aceptación de los presentes Términos y Condiciones, así como de los
          contratos de adhesión respectivos de cada Producto, es el medio por el que manifiestas el
          consentimiento expreso de someterse a los mismos.
        </p>

        <p>
          Adicionalmente, el USUARIO acepta y reconoce que la manifestación del consentimiento
          respecto de los Servicios contratados a través del SITIO WEB, así como las autorizaciones
          a POPNEST necesarias para su contratación, podrán ser mediante medios electrónicos tales
          como llamada telefónica, WhatsApp, o correo electrónico.
        </p>
        <h3 className={'Subtitle-2 margt40'}>X. DE LA RESERVACIÓN Y DISPONIBILIDAD DE ESPACIOS</h3>

        <p>
          El USUARIO podrá efectuar la reservación de conformidad con la disponibilidad del espacio
          y las condiciones establecidas que incluyen tanto el máximo de personas a ingresar y el
          mínimo de contratación de horas o días.
        </p>

        <p>
          POPNEST a través de su SITIO WEB oferta una variedad de espacios brindando información
          sobre su ubicación, ofreciendo imágenes que contienen una representación visual de cada
          inmueble, aunque se destaca que dichas imágenes pueden presentar variaciones en cuanto
          color e iluminación.
        </p>

        <p>
          La información detallada del inmueble se proporciona una vez que el USUARIO manifiesta el
          interés de reservar o de realizar el pago correspondiente. De esta manera se obtiene
          acceso a detalles específicos sobre el espacio, tales como características destacadas,
          amenidades y cualquier información relevante para su estancia. Esto optimiza la
          experiencia del USUARIO para tomar una decisión informada, garantizando que la información
          proporcionada por POPNEST sea actualizada y brinde confianza al USUARIO al momento de
          realizar su reserva.
        </p>

        <h3 className={'Subtitle-2 margt40'}>XI. DEL CAMBIO DE LA RESERVACIÓN</h3>

        <p>
          El USUARIO tiene la posibilidad de cambiar su reserva una vez que se refleje el pago total
          correspondiente, por un espacio de igual o superior precio, siempre y cuando realice la
          solicitud de cambio con al menos 15 días naturales de antelación a la fecha de la reserva.
          Así mismo, cualquier cambio estará sujeto a la disponibilidad existente en ese momento.
        </p>

        <p>
          El USUARIO se compromete a pagar cualquier cantidad adicional, comisión o impuesto que
          esté vinculado con la modificación.
        </p>

        <h3 className={'Subtitle-2 margt40'}>XII. DE LA MODIFICACIÓN DE LA RESERVACIÓN</h3>

        <p>
          El USUARIO podrá llevar a cabo la modificación de su itinerario para extender la duración
          de estancia en el mismo espacio o modificar la fecha de entrada, una vez que se refleje el
          pago total correspondiente, siempre y cuando dicha solicitud se realice con al menos 15
          días naturales de anticipación a la fecha de la reserva. Dicha modificación estará sujeta
          a la disponibilidad de fechas del espacio.
        </p>

        <p>
          Si el USUARIO desea extender la duración de su estancia y añadir horas o días a su reserva
          existente, debe ponerse en contacto con POPNEST durante su estancia. El proceso se
          facilitará para llevar a cabo el pago correspondiente y garantizar la continuidad de la
          reserva, sujeto a la disponibilidad del espacio. Asimismo, el USUARIO se compromete a
          pagar cualquier cantidad adicional, comisión o impuesto que esté vinculado con la
          modificación.
        </p>

        <p>
          En caso de que el USUARIO requiera prolongar su estancia después de haber concluido su
          reserva inicial, deberá realizar una nueva reserva.
        </p>

        <h3 className={'Subtitle-2 margt40'}>XIII. DE LA CANCELACIÓN Y DEVOLUCIÓN DE LA RESERVACIÓN</h3>

        <p>
          El USUARIO podrá llevar a cabo la cancelación de su reservación una vez que se refleje el
          pago total correspondiente y recibirá el reembolso de acuerdo a lo siguiente:
        </p>

        <ol>
          <li>
            Para recibir el 90% de devolución del importe pagado por concepto de reservación de
            algún espacio, el USUARIO deberá cancelar con 30 días naturales de anticipación a la
            fecha de inicio del alojamiento.
          </li>
          <li>
            Para recibir el 70% de devolución del importe pagado por concepto de reservación de
            algún espacio, el USUARIO deberá realizar la cancelación con una antelación de 29 a 15
            días naturales previos a la fecha programada para el inicio del alojamiento.
          </li>
          <li>
            Para recibir el 50% de devolución del importe pagado por concepto de reservación de
            espacio, el USUARIO deberá realizar la cancelación con una antelación de 14 a 10 días
            naturales previos a la fecha programada para el inicio del alojamiento.
          </li>
          <li>
            La política de reembolso quedará sin efecto si el USUARIO solicita la cancelación dentro
            de un plazo de 9 días naturales y hasta 24 horas antes de la fecha de inicio del
            alojamiento.
          </li>
        </ol>

        <table>
          <tr>
            <th>DÍAS DE ANTICIPACIÓN A LA FECHA DE RESERVA</th>
            <th>PORCENTAJE DE DEVOLUCIÓN</th>
          </tr>
          <tr>
            <td>Hasta 30 días naturales</td>
            <td>90%</td>
          </tr>
          <tr>
            <td>De 29 a 15 días naturales</td>
            <td>70%</td>
          </tr>
          <tr>
            <td>De 14 a 10 días naturales</td>
            <td>50%</td>
          </tr>
          <tr>
            <td>9 días naturales o menos</td>
            <td>No aplica reembolso</td>
          </tr>
        </table>

        <p>
          Se entiende por “día 0” el día en el que el USUARIO hace uso del espacio, y por “día 1” el
          día previo al inicio del alojamiento.
        </p>

        <h3 className={'Subtitle-2 margt40'}>XIV. DE LA POLÍTICA DE RESPONSABILIDAD DEL USUARIO</h3>

        <p>
          El USUARIO asume la responsabilidad de sus acciones y omisiones dentro del espacio
          proporcionado por el Anfitrión. El USUARIO:
        </p>

        <ul>
          <li>Deberá ocupar y desocupar el espacio en el horario convenido.</li>
          <li>
            Tiene la responsabilidad de dejar el espacio en el mismo estado en que se encontraba al
            momento de su llegada.
          </li>
          <li>
            Es responsable de cubrir los montos de reclamación por daños para compensar los
            perjuicios causados por el USUARIO, huéspedes o mascotas a un espacio.
          </li>
          <li>
            Debe mantener un comportamiento íntegro y cumplir con las leyes aplicables en todo
            momento.
          </li>
        </ul>

        <h3 className={'Subtitle-2 margt40'}>XV. DEL PAGO</h3>

        <p>
          El SITIO WEB utiliza un sistema de cobro operado por STRIPE Payments en el cual el USUARIO
          puede escoger entre los siguientes métodos de pago: ApplePay, Oxxo (por medio de una
          referencia de pago), y tarjeta de débito y crédito con procesadores de pago Visa,
          MasterCard y American Express.
        </p>

        <h3 className={'Subtitle-2 margt40'}>XVI. DE LAS DUDAS, RECLAMACIONES O ACLARACIONES</h3>

        <p>
          El USUARIO puede emitir dudas, reclamaciones o aclaraciones a POPNEST a través del correo
          electrónico: soporte@popnest.org
        </p>

        <h3 className={'Subtitle-2 margt40'}>XVII. DE LA ATENCIÓN AL CLIENTE</h3>

        <p>
          POPNEST pone a disposición del USUARIO el correo electrónico soporte@popnest.org, mediante
          el cual puede comunicarse con el área de Atención a Clientes para resolver cualquier
          cuestión relativa al uso del SITIO WEB o la compra de productos.
        </p>

        <h3 className={'Subtitle-2 margt40'}>XVIII. DE LOS DAÑOS</h3>

        <p>
          POPNEST no es responsable de cualquier daño que se ocasione en el espacio contratado por
          el USUARIO. El USUARIO asume toda la responsabilidad por el uso del espacio, incluyendo la
          conservación adecuada de las instalaciones, la seguridad de los objetos personales y la
          conducta general durante su estancia. El USUARIO exime a POPNEST de cualquier
          responsabilidad relacionada con incidentes o accidentes durante el uso del espacio
          contratado.
        </p>

        <p>
          Los acuerdos pactados entre el USUARIO y el Anfitrión se desarrollan de manera
          independiente a la participación de POPNEST, y cualquier disputa o desacuerdo derivado de
          dicha interacción no involucra a POPNEST.
        </p>

        <h3 className={'Subtitle-2 margt40'}>XIX. DE LA LEGISLACIÓN Y JURISDICCIÓN APLICABLE</h3>

        <p>
          POPNEST se reserva la facultad de todas las acciones y/o derechos que considere necesarios
          contra el USUARIO por la utilización indebida del SITIO WEB, sus contenidos, productos o
          servicios, o por el incumplimiento de los presentes TÉRMINOS Y CONDICIONES.
        </p>

        <p>
          La relación entre el USUARIO y POPNEST se regirá por la legislación vigente en México,
          específicamente en la Ciudad de México. De surgir controversias relacionadas con la
          interpretación y/o aplicación de los presentes TÉRMINOS Y CONDICIONES, las partes se
          someterán a la jurisdicción ordinaria de los tribunales correspondientes conforme a
          derecho en el estado referido.
        </p>
      </Label>
    </PageContainer>
  );
};

export default TermsAndConditionsGuest;
