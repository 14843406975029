import { useQuery, useMutation } from '@tanstack/react-query';

const apiUrl = process.env.REACT_APP_API_URL;

export const fetchAllSpacesSubtypeRelationships = async () => {
  const response = await fetch(`${apiUrl}/GET/spaces_subtype_relationship`);
  if (!response.ok) {
    throw new Error('Error fetching spaces subtype relationships');
  }
  return response.json();
};

export const fetchSpaceSubtypeRelationshipById = async id => {
  const response = await fetch(
    `${apiUrl}/GET/spaces_subtype_relationship/${id}`,
  );
  if (!response.ok) {
    throw new Error('Error fetching space subtype relationship by ID');
  }
  return response.json();
};

export const createSpaceSubtypeRelationship = async relationshipData => {
  if (!Array.isArray(relationshipData)) {
    throw new Error('relationshipData must be an array of objects');
  }
  const response = await fetch(`${apiUrl}/POST/spaces_subtype_relationship`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(relationshipData),
  });

  if (!response.ok) {
    throw new Error('Error creating space subtype relationship');
  }
  return response.json();
};

export const updateSpaceSubtypeRelationship = async ({
  id,
  relationshipData,
}) => {
  const response = await fetch(
    `${apiUrl}/PUT/spaces_subtype_relationship/${id}`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(relationshipData),
    },
  );
  if (!response.ok) {
    throw new Error('Error updating space subtype relationship');
  }
  return response.json();
};

export const deleteSpaceSubtypeRelationship = async id => {
  const response = await fetch(
    `${apiUrl}/PUT/delete_spaces_subtype_relationship/${id}`,
    {
      method: 'PUT',
    },
  );

  if (!response.ok) {
    throw new Error('Error deleting space subtype relationship');
  }
  return response.json();
};

export const deleteSpacesSubtypeRelationshipsSeveral =
  async relationshipIds => {
    const response = await fetch(
      `${apiUrl}/PUT/delete_spaces_subtype_relationship_several`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ relationshipIds }),
      },
    );

    if (!response.ok) {
      throw new Error('Error deleting multiple space subtype relationships');
    }
    return response.json();
  };

export const enabledSpacesSubtypeRelationshipsSeveral =
  async relationshipIds => {
    const response = await fetch(
      `${apiUrl}/PUT/enabled_spaces_subtype_relationship_several`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ relationshipIds }),
      },
    );

    if (!response.ok) {
      throw new Error('Error enabling multiple space subtype relationships');
    }
    return response.json();
  };

export const disabledSpacesSubtypeRelationshipsSeveral =
  async relationshipIds => {
    const response = await fetch(
      `${apiUrl}/PUT/disabled_spaces_subtype_relationship_several`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ relationshipIds }),
      },
    );

    if (!response.ok) {
      throw new Error('Error disabling multiple space subtype relationships');
    }
    return response.json();
  };

export const useFetchAllSpacesSubtypeRelationships = () => {
  return useQuery({
    queryKey: ['spacesSubtypeRelationships'],
    queryFn: fetchAllSpacesSubtypeRelationships,
  });
};

export const useFetchSpaceSubtypeRelationshipById = id => {
  return useQuery({
    queryKey: ['spaceSubtypeRelationship', id],
    queryFn: () => fetchSpaceSubtypeRelationshipById(id),
    enabled: !!id,
  });
};

export const useCreateSpaceSubtypeRelationship = () => {
  return useMutation({
    mutationFn: createSpaceSubtypeRelationship,
    onSuccess: () => {
      console.log('Space subtype relationship created successfully');
    },
    onError: error => {
      console.error('Error creating space subtype relationship:', error);
    },
  });
};

export const useUpdateSpaceSubtypeRelationship = () => {
  return useMutation({
    mutationFn: updateSpaceSubtypeRelationship,
    onSuccess: () => {
      console.log('Space subtype relationship updated successfully');
    },
    onError: error => {
      console.error('Error updating space subtype relationship:', error);
    },
  });
};

export const useDeleteSpaceSubtypeRelationship = () => {
  return useMutation({
    mutationFn: deleteSpaceSubtypeRelationship,
    onSuccess: () => {
      console.log('Space subtype relationship deleted successfully');
    },
    onError: error => {
      console.error('Error deleting space subtype relationship:', error);
    },
  });
};

export const useDeleteSpacesSubtypeRelationshipsSeveral = () => {
  return useMutation({
    mutationFn: deleteSpacesSubtypeRelationshipsSeveral,
    onSuccess: () => {
      console.log('Multiple space subtype relationships deleted successfully');
    },
    onError: error => {
      console.error(
        'Error deleting multiple space subtype relationships:',
        error,
      );
    },
  });
};

export const useEnabledSpacesSubtypeRelationshipsSeveral = () => {
  return useMutation({
    mutationFn: enabledSpacesSubtypeRelationshipsSeveral,
    onSuccess: () => {
      console.log('Multiple space subtype relationships enabled successfully');
    },
    onError: error => {
      console.error(
        'Error enabling multiple space subtype relationships:',
        error,
      );
    },
  });
};

export const useDisabledSpacesSubtypeRelationshipsSeveral = () => {
  return useMutation({
    mutationFn: disabledSpacesSubtypeRelationshipsSeveral,
    onSuccess: () => {
      console.log('Multiple space subtype relationships disabled successfully');
    },
    onError: error => {
      console.error(
        'Error disabling multiple space subtype relationships:',
        error,
      );
    },
  });
};
