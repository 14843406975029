import React, { useEffect } from 'react';
import { usePrivacyConditions } from './hooks/PrivacyConditions.hook';
import PrivacyConditionsView from './views/PrivacyConditionsView';
import LoadingComponent from '@/components/general/Loading/LoadingComponent';

const PrivacyConditionsController = () => {
  const { isLoading, spacesData } = usePrivacyConditions();
  if (isLoading) {
    return <LoadingComponent loading={isLoading} />;
  } else {
    return <PrivacyConditionsView spacesData={spacesData} />;
  }
};

export default PrivacyConditionsController;
