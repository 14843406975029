const API_KEY =
  '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';

export const chatWithGPT = async (message) => {
  const response = await fetch('https://api.openai.com/v1/chat/completions', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${API_KEY}`,
    },
    body: JSON.stringify({
      model: 'gpt-3.5-turbo',
      messages: [
        { role: 'system', content: 'Eres un asistente útil.' },
        { role: 'user', content: message },
      ],
      temperature: 0.7,
    }),
  });

  if (!response.ok) {
    throw new Error('Error en la solicitud a la API de OpenAI');
  }

  return response.json();
};


