import React, { useEffect } from 'react';
import { useNotFound } from './hooks/NotFound.hook';
import NotFoundView from './views/NotFoundView';
import LoadingComponent from '@/components/general/Loading/LoadingComponent';

const NotFoundController = () => {
  const { isLoading, spacesData } = useNotFound();
  if (isLoading) {
    return <LoadingComponent loading={isLoading} />;
  } else {
    return <NotFoundView spacesData={spacesData} />;
  }
};

export default NotFoundController;
