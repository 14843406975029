import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useCreateSpace, createSpace, updateSpaceMainImage } from '@/services/spacesService';
import { chatWithGPT } from '@/services/openAiService';
import { createSpaceSubtypeRelationship } from '@/services/spacesSubtypeRelationshipService';
import { TextField, Grid, Box, Button, Typography, Alert } from '@mui/material';
import { useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { getSpacesValidationSchema as validationSchema } from '../SpacesForm/Validations/ValidationSpacesSchema';
import { getOrdinal } from '../SpacesForm/Validations/NumberOrdinalConfig';
import Label from '@/components/form/Label/Label';

const SpaceForm = () => {
  //const { mutate } = useCreateSpace();
  const [successMessage, setSuccessMessage] = useState('');
  const [chatLog, setChatLog] = useState([]);

  const editorRef = useRef(null);

  const handleSubmit = async (values, { resetForm }) => {
    console.log(JSON.stringify(values));
    try {
      //await mutate(values);
      const createdSpace = await createSpace(values);
      if (!createdSpace || !createdSpace.space_id) {
        throw new Error('No se recibió un user_id válido al crear el usuario');
      }
      const spaceId = createdSpace.space_id;
      console.log('Space ID obtenido de la respuesta:', spaceId);
      if (values.main_image && values.main_image.length > 0) {
        await updateSpaceMainImage(spaceId, values.main_image);
      }
      let subtypeRelationshipValues = values?.subtypeRelationship || [];
      subtypeRelationshipValues = subtypeRelationshipValues.map((subtype) => ({
        ...subtype,
        space_id: spaceId,
      }));

      console.log('subtypeRelationshipValues:', subtypeRelationshipValues);

      if (subtypeRelationshipValues.length > 0) {
        await createSpaceSubtypeRelationship(subtypeRelationshipValues);
        console.log('Relaciones de subtipo creadas con éxito');
      } else {
        console.warn('No hay relaciones de subtipo válidas para enviar.');
      }
      console.log('Espacio creado y/o imagen actualizada con éxito');
      setSuccessMessage('Espacio creado y/o imagen actualizada con éxito');
      resetForm();
    } catch (error) {
      console.error('Error al crear o actualizar el espacio:', error);
      setSuccessMessage('Error al crear o actualizar el espacio');
    }
  };

  return (
    <Formik
      initialValues={{
        space_name: '',
        space_address: '',
        space_title: '',
        main_image: [],
        space_state: 1,
        space_details: '',
        host_id: '',
        house_number: '',
        space_latitude: '',
        space_logitude: '',
        street_reference: '',
        zip_code: '',
        is_reserved: 0,
        gmaps_url: '',
        subtypeRelationship: [
          {
            subtype_id: 1,
            space_details: '',
            host_capacity: '',
            pet_friendly: 0,
            coffe_water: 0,
            hourly_rate: '',
            rate_4_hours: '',
            rate_8_hours: '',
            state_id: 1,
            parking: 0,
            parking_details: '',
            main_image: [],
          },
        ],
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ setFieldValue, values, errors, touched }) => {
        console.log('Valores del formulario:', values);
        console.log('Errores de validación:', errors);
        console.log('Campos tocados:', touched);

        const handleSendMessageToGpt = async () => {
          const message = '';
          if (!message.trim()) return;
          console.log('Hola');

          try {
            console.log('Enviando mensaje:', message);
            const data = await chatWithGPT(message);
            const reply = data.choices[0].message.content;

            console.log('Respuesta de ChatGPT:', reply);

            setChatLog((prev) => [...prev, { user: message, assistant: reply }]);
          } catch (err) {
            console.error('Error al llamar a ChatGPT:', err);
          }
          setFieldValue(
            'space_details',
            values.space_name +
              ' es un espacio diseñado para satisfacer todas tus necesidades profesionales. El lugar cuenta con salas con instalaciones de última generación y de vanguardia',
          );
        };

        return (
          <Form>
            <Typography variant="h4" component="h1" align="center" gutterBottom>
              Crear Espacios
            </Typography>

            <Box sx={{ padding: '20px', maxWidth: '600px', margin: '0 auto' }}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Field name="space_name">
                    {({ field }) => (
                      <TextField
                        label="Space Name"
                        fullWidth
                        {...field}
                        error={Boolean(touched.space_name && errors.space_name)}
                        helperText={touched.space_name && errors.space_name}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field name="space_address">
                    {({ field }) => (
                      <TextField
                        label="Space Address"
                        fullWidth
                        {...field}
                        error={Boolean(touched.space_address && errors.space_address)}
                        helperText={touched.space_address && errors.space_address}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field name="space_title">
                    {({ field }) => (
                      <TextField
                        label="Space Title"
                        fullWidth
                        {...field}
                        error={Boolean(touched.space_title && errors.space_title)}
                        helperText={touched.space_title && errors.space_title}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12}>
                  <Field name="main_image">
                    {({ field, form: { setFieldValue, touched, errors } }) => (
                      <>
                        <Button
                          variant="contained"
                          component="label"
                          color="primary"
                          sx={{ marginTop: '10px' }}
                        >
                          Cargar Imágenes
                          <input
                            type="file"
                            hidden
                            multiple
                            onChange={(e) => {
                              const files = Array.from(e.currentTarget.files);
                              setFieldValue('main_image', files);
                              setFieldValue('imageCount', files.length);
                            }}
                            accept="image/*"
                          />
                        </Button>

                        {field.value && field.value.length > 0 && (
                          <p>{field.value.length} imagen(es) seleccionada(s)</p>
                        )}
                        {errors.main_image && touched.main_image && (
                          <div style={{ color: 'red' }}>{errors.main_image}</div>
                        )}
                      </>
                    )}
                  </Field>
                </Grid>

                <Grid item xs={12}>
                  <Field name="space_details">
                    {() => (
                      <div>
                        <Label className={'margb10'}>
                          Space Details{' '}
                          <Button
                            variant="contained"
                            component="label"
                            color="secondary"
                            sx={{ marginLeft: '10px' }}
                            onClick={handleSendMessageToGpt}
                          >
                            Mejorar con IA
                          </Button>
                        </Label>

                        <Editor
                          apiKey="2qddd2ef9jx93qzld2ixgyp8lj3esyhzkninni15l4495lnb"
                          onInit={(evt, editor) => (editorRef.current = editor)}
                          value={values.space_details}
                          init={{
                            height: 300,
                            directionality: 'ltr',
                            menubar: true,
                            language: 'en',
                            plugins: [
                              'advlist autolink lists link image charmap print preview anchor',
                              'searchreplace visualblocks code fullscreen',
                              'insertdatetime media table paste code help wordcount',
                            ],
                            toolbar:
                              'undo redo | formatselect | bold italic backcolor | \
                      alignleft aligncenter alignright alignjustify | \
                      bullist numlist outdent indent | removeformat | help',
                          }}
                          onEditorChange={(content) => {
                            setFieldValue('space_details', content);
                          }}
                        />
                        {touched.space_details && errors.space_details && (
                          <div style={{ color: 'red', marginTop: '0.5rem' }}>
                            {errors.space_details}
                          </div>
                        )}
                      </div>
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field name="host_id">
                    {({ field }) => (
                      <TextField
                        label="Host ID"
                        fullWidth
                        {...field}
                        error={Boolean(touched.host_id && errors.host_id)}
                        helperText={touched.host_id && errors.host_id}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field name="house_number">
                    {({ field }) => (
                      <TextField
                        label="House Number"
                        fullWidth
                        {...field}
                        error={Boolean(touched.house_number && errors.house_number)}
                        helperText={touched.house_number && errors.house_number}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field name="space_latitude">
                    {({ field }) => (
                      <TextField
                        label="Latitude"
                        fullWidth
                        {...field}
                        error={Boolean(touched.space_latitude && errors.space_latitude)}
                        helperText={touched.space_latitude && errors.space_latitude}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field name="space_logitude">
                    {({ field }) => (
                      <TextField
                        label="Longitude"
                        fullWidth
                        {...field}
                        error={Boolean(touched.space_logitude && errors.space_logitude)}
                        helperText={touched.space_logitude && errors.space_logitude}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12}>
                  <Field name="street_reference">
                    {({ field }) => (
                      <TextField
                        label="Street Reference"
                        fullWidth
                        {...field}
                        error={Boolean(touched.street_reference && errors.street_reference)}
                        helperText={touched.street_reference && errors.street_reference}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field name="zip_code">
                    {({ field }) => (
                      <TextField
                        label="Zip Code"
                        type="number"
                        fullWidth
                        {...field}
                        error={Boolean(touched.zip_code && errors.zip_code)}
                        helperText={touched.zip_code && errors.zip_code}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field name="is_reserved">
                    {({ field }) => (
                      <TextField
                        select
                        label="Reservado"
                        fullWidth
                        {...field}
                        SelectProps={{ native: true }}
                        error={Boolean(touched.is_reserved && errors.is_reserved)}
                        helperText={touched.is_reserved && errors.is_reserved}
                      >
                        <option value="0">No reservado</option>
                        <option value="1">Reservado</option>
                      </TextField>
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12}>
                  <Field name="gmaps_url">
                    {({ field }) => (
                      <TextField
                        label="Google Maps URL"
                        fullWidth
                        {...field}
                        error={Boolean(touched.gmaps_url && errors.gmaps_url)}
                        helperText={touched.gmaps_url && errors.gmaps_url}
                      />
                    )}
                  </Field>
                </Grid>
                {values.subtypeRelationship.map((subtype, index) => (
                  <React.Fragment key={index}>
                    <Grid item xs={12}>
                      <Typography variant="h6" sx={{ marginBottom: '10px' }}>
                        {index === 0
                          ? 'Primera Relación'
                          : index === 1
                          ? 'Segunda Relación'
                          : getOrdinal().getOrdinal(index + 1) + ' Relación'}
                      </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Field name={`subtypeRelationship[${index}].subtype_id`}>
                        {({ field }) => (
                          <TextField
                            select
                            label="Subtype"
                            fullWidth
                            {...field}
                            SelectProps={{ native: true }}
                            error={Boolean(
                              touched.subtypeRelationship?.[index]?.subtype_id &&
                                errors.subtypeRelationship?.[index]?.subtype_id,
                            )}
                            helperText={
                              touched.subtypeRelationship?.[index]?.subtype_id &&
                              errors.subtypeRelationship?.[index]?.subtype_id
                            }
                          >
                            <option value="1">Coworking</option>
                            <option value="2">Sala de Juntas</option>
                            <option value="4">Terraza</option>
                            <option value="5">Oficina Virtual</option>
                            <option value="6">Oficina</option>
                            <option value="8">Estudio</option>
                            <option value="9">Sala</option>
                          </TextField>
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field name={`subtypeRelationship[${index}].state_id`}>
                        {({ field }) => (
                          <TextField
                            select
                            label="State"
                            fullWidth
                            {...field}
                            SelectProps={{ native: true }}
                            error={Boolean(
                              touched.subtypeRelationship?.[index]?.state_id &&
                                errors.subtypeRelationship?.[index]?.state_id,
                            )}
                            helperText={
                              touched.subtypeRelationship?.[index]?.state_id &&
                              errors.subtypeRelationship?.[index]?.state_id
                            }
                          >
                            <option value="2">Inactivo</option>
                            <option value="1">Activo</option>
                          </TextField>
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={12}>
                      <Field name={`subtypeRelationship[${index}].space_details`}>
                        {() => (
                          <div>
                            <Label className={'margb10'}>
                              Space Type Details
                              <Button
                                variant="contained"
                                component="label"
                                color="secondary"
                                sx={{ marginLeft: '10px' }}
                                onClick={handleSendMessageToGpt}
                              >
                                Mejorar con IA
                              </Button>
                            </Label>
                            <Editor
                              apiKey="2qddd2ef9jx93qzld2ixgyp8lj3esyhzkninni15l4495lnb"
                              onInit={(evt, editor) => (editorRef.current[index] = editor)}
                              value={values.subtypeRelationship[index].space_details}
                              init={{
                                height: 300,
                                directionality: 'ltr',
                                menubar: true,
                                language: 'en',
                                plugins: [
                                  'advlist autolink lists link image charmap print preview anchor',
                                  'searchreplace visualblocks code fullscreen',
                                  'insertdatetime media table paste code help wordcount',
                                ],
                                toolbar:
                                  'undo redo | formatselect | bold italic backcolor | \
                              alignleft aligncenter alignright alignjustify | \
                              bullist numlist outdent indent | removeformat | help',
                              }}
                              onEditorChange={(content) =>
                                setFieldValue(
                                  `subtypeRelationship[${index}].space_details`,
                                  content,
                                )
                              }
                            />
                          </div>
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field name={`subtypeRelationship[${index}].host_capacity`}>
                        {({ field }) => (
                          <TextField
                            label="Host Capacity"
                            type="number"
                            fullWidth
                            {...field}
                            error={Boolean(
                              touched.subtypeRelationship?.[index]?.host_capacity &&
                                errors.subtypeRelationship?.[index]?.host_capacity,
                            )}
                            helperText={
                              touched.subtypeRelationship?.[index]?.host_capacity &&
                              errors.subtypeRelationship?.[index]?.host_capacity
                            }
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field name={`subtypeRelationship[${index}].pet_friendly`}>
                        {({ field }) => (
                          <TextField
                            select
                            label="Pet Friendly"
                            fullWidth
                            {...field}
                            SelectProps={{ native: true }}
                            error={Boolean(
                              touched.subtypeRelationship?.[index]?.pet_friendly &&
                                errors.subtypeRelationship?.[index]?.pet_friendly,
                            )}
                            helperText={
                              touched.subtypeRelationship?.[index]?.pet_friendly &&
                              errors.subtypeRelationship?.[index]?.pet_friendly
                            }
                          >
                            <option value="0">No</option>
                            <option value="1">Yes</option>
                          </TextField>
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field name={`subtypeRelationship[${index}].coffe_water`}>
                        {({ field }) => (
                          <TextField
                            select
                            label="Coffee/Water"
                            fullWidth
                            {...field}
                            SelectProps={{ native: true }}
                            error={Boolean(
                              touched.subtypeRelationship?.[index]?.coffe_water &&
                                errors.subtypeRelationship?.[index]?.coffe_water,
                            )}
                            helperText={
                              touched.subtypeRelationship?.[index]?.coffe_water &&
                              errors.subtypeRelationship?.[index]?.coffe_water
                            }
                          >
                            <option value="0">No</option>
                            <option value="1">Yes</option>
                          </TextField>
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field name={`subtypeRelationship[${index}].hourly_rate`}>
                        {({ field }) => (
                          <TextField
                            label="Hourly Rate"
                            type="number"
                            fullWidth
                            {...field}
                            error={Boolean(
                              touched.subtypeRelationship?.[index]?.hourly_rate &&
                                errors.subtypeRelationship?.[index]?.hourly_rate,
                            )}
                            helperText={
                              touched.subtypeRelationship?.[index]?.hourly_rate &&
                              errors.subtypeRelationship?.[index]?.hourly_rate
                            }
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field name={`subtypeRelationship[${index}].rate_4_hours`}>
                        {({ field }) => (
                          <TextField
                            label="4 Hours Rate"
                            type="number"
                            fullWidth
                            {...field}
                            error={Boolean(
                              touched.subtypeRelationship?.[index]?.rate_4_hours &&
                                errors.subtypeRelationship?.[index]?.rate_4_hours,
                            )}
                            helperText={
                              touched.subtypeRelationship?.[index]?.rate_4_hours &&
                              errors.subtypeRelationship?.[index]?.rate_4_hours
                            }
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field name={`subtypeRelationship[${index}].rate_8_hours`}>
                        {({ field }) => (
                          <TextField
                            label="8 Hours Rate"
                            type="number"
                            fullWidth
                            {...field}
                            error={Boolean(
                              touched.subtypeRelationship?.[index]?.rate_8_hours &&
                                errors.subtypeRelationship?.[index]?.rate_8_hours,
                            )}
                            helperText={
                              touched.subtypeRelationship?.[index]?.rate_8_hours &&
                              errors.subtypeRelationship?.[index]?.rate_8_hours
                            }
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field name={`subtypeRelationship[${index}].parking`}>
                        {({ field }) => (
                          <TextField
                            select
                            label="Parking"
                            fullWidth
                            {...field}
                            SelectProps={{ native: true }}
                            error={Boolean(
                              touched.subtypeRelationship?.[index]?.parking &&
                                errors.subtypeRelationship?.[index]?.parking,
                            )}
                            helperText={
                              touched.subtypeRelationship?.[index]?.parking &&
                              errors.subtypeRelationship?.[index]?.parking
                            }
                          >
                            <option value="0">No</option>
                            <option value="1">Yes</option>
                          </TextField>
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={12}>
                      <Field name={`subtypeRelationship[${index}].parking_details`}>
                        {({ field }) => (
                          <TextField
                            label="Parking Details"
                            fullWidth
                            {...field}
                            error={Boolean(
                              touched.subtypeRelationship?.[index]?.parking_details &&
                                errors.subtypeRelationship?.[index]?.parking_details,
                            )}
                            helperText={
                              touched.subtypeRelationship?.[index]?.parking_details &&
                              errors.subtypeRelationship?.[index]?.parking_details
                            }
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={12}>
                      <Field name={`subtypeRelationship[${index}].main_image`}>
                        {({ field, form: { setFieldValue, touched, errors } }) => (
                          <>
                            <Button
                              variant="contained"
                              component="label"
                              color="primary"
                              sx={{ marginTop: '10px' }}
                            >
                              Cargar Imágenes Relación
                              <input
                                type="file"
                                hidden
                                multiple
                                onChange={(e) => {
                                  const files = Array.from(e.currentTarget.files);
                                  setFieldValue('main_image', files);
                                  setFieldValue('imageCount', files.length);
                                }}
                                accept="image/*"
                              />
                            </Button>

                            {field.value && field.value.length > 0 && (
                              <p>{field.value.length} imagen(es) seleccionada(s)</p>
                            )}
                            {errors.main_image && touched.main_image && (
                              <div style={{ color: 'red' }}>{errors.main_image}</div>
                            )}
                          </>
                        )}
                      </Field>
                    </Grid>
                  </React.Fragment>
                ))}

                <Grid item xs={12}>
                  <Box
                    sx={{
                      textAlign: 'center',
                      display: 'flex',
                      justifyContent: 'center',
                      gap: '10px',
                    }}
                  >
                    <Button
                      type="button"
                      variant="contained"
                      sx={{ paddingX: '20px', paddingY: '10px' }}
                      onClick={() => {
                        const newSubsection = {
                          subtype_id: 1,
                          space_details: '',
                          host_capacity: '',
                          pet_friendly: 0,
                          coffe_water: 0,
                          hourly_rate: '',
                          rate_4_hours: '',
                          rate_8_hours: '',
                          state_id: 1,
                          parking: 0,
                          parking_details: '',
                        };
                        setFieldValue('subtypeRelationship', [
                          ...values.subtypeRelationship,
                          newSubsection,
                        ]);
                      }}
                    >
                      Agregar nueva relación
                    </Button>
                    {values.subtypeRelationship.length > 1 && (
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() => {
                          const newSubsections = values.subtypeRelationship.slice(0, -1);
                          setFieldValue('subtypeRelationship', newSubsections);
                        }}
                        sx={{
                          paddingX: '20px',
                          paddingY: '10px',
                          backgroundColor: 'red',
                          color: 'white',
                          borderColor: 'red',
                          '&:hover': {
                            backgroundColor: '#d32f2f',
                            borderColor: '#d32f2f',
                            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                          },
                          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
                          marginLeft: '10px',
                        }}
                      >
                        Eliminar subsección
                      </Button>
                    )}
                  </Box>
                </Grid>
              </Grid>

              {successMessage && (
                <Box sx={{ marginTop: '20px', textAlign: 'center' }}>
                  <Alert severity={successMessage.includes('Error') ? 'error' : 'success'}>
                    {successMessage}
                  </Alert>
                </Box>
              )}
              <Box sx={{ textAlign: 'center', marginTop: '20px' }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{ paddingX: '20px', paddingY: '10px' }}
                >
                  Crear Espacio
                </Button>
              </Box>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};

export default SpaceForm;
