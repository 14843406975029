import React, { useEffect } from 'react';
import { useCheckout } from './hooks/Checkout.hook';
import { useLocation } from 'react-router-dom';
import CheckoutView from './views/CheckoutView';
import LoadingComponent from '@/components/general/Loading/LoadingComponent';

const CheckoutController = () => {
  const { isLoading, spacesData } = useCheckout();
  const location = useLocation();
  const { spaceDetail, values } = location.state || {};
  if (isLoading) {
    return <LoadingComponent loading={isLoading} />;
  } else {
    return <CheckoutView spacesData={spacesData} spaceDetail={spaceDetail} formValues={values} />;
  }
};

export default CheckoutController;
