import React, { useState } from 'react';
import { useCreateAdditionalGuest } from '@/services/additionalGuestsService';
import SpaceForm from '@/components/general/Forms/SpacesForm/SpaceForm';

const CreacionSpace = () => {
  const [guestData, setGuestData] = useState({
    id_additional_guest: '',
    transaction_id: '',
    name_guest: '',
    lastname_guest: '',
    email_guest: '',
    id_state: '',
    phone_guest: '',
  });

  const { mutate, isLoading, isError, error } = useCreateAdditionalGuest();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setGuestData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    mutate(guestData, {
      onSuccess: (data) => {
        console.log('Creation created successfully:', data);
      },
      onError: (error) => {
        console.error('Error creating:', error);
      },
    });
  };

  return (
    <>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      {/* <SpaceForm /> */}
      <SpaceForm />
      {/*       <UserForm2 />
       */}{' '}
    </>
  );
};

export default CreacionSpace;
