export const setSearchFormData = (searchFormData) => {
  console.log('Dispatching SET_SEARCH_FORM_DATA with:', searchFormData);
  return {
    type: 'SET_SEARCH_FORM_DATA',
    payload: searchFormData,
  };
};

export const clearSearchFormDataActions = () => ({
  type: 'CLEAR_SEARCH_FORM_DATA',
});
