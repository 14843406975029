import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Home } from '@/components/pages/Home';
import { About } from '@/components/pages/About';
import { ProductPreList } from '@/components/pages/ProductPreList';
import { ProductDetails } from '@/components/pages/ProductDetails';
import { NotFound } from '@/components/pages/NotFound';
import { Checkout } from '@/components/pages/Checkout';
import { ReservationSuccess } from '@/components/pages/ReservationSuccess';
import { TermsAndConditions } from '@/components/pages/TermsAndConditions';
import { TermsAndConditionsGuest } from '@/components/pages/TermsAndConditionsGuest';
import { PrivacyConditions } from '@/components/pages/PrivacyConditions';
import CreacionSpace from '@/components/pages/Home/CreacionSpace';
import CreacionUser from '@/components/pages/Home/CreacionUser';

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/spaces" element={<About />} />
      <Route path="/yourSpaces" element={<About />} />
      <Route path="/productPreList" element={<ProductPreList />} />
      <Route path="/productDetails" element={<ProductDetails />} />
      <Route path="/ReservationSuccess" element={<ReservationSuccess />} />
      <Route path="/checkout" element={<Checkout />} />
      <Route path="/termsAndConditionsHost" element={<TermsAndConditions />} />
      <Route path="/termsAndConditionsGuest" element={<TermsAndConditionsGuest />} />
      <Route path="/privacyConditions" element={<PrivacyConditions />} />
      <Route path="*" element={<NotFound />} />
      <Route path="/creationUser" element={<CreacionUser />} />
      <Route path="/creationSpace" element={<CreacionSpace />} />
    </Routes>
  );
};

export default AppRoutes;
