import React from 'react';
import { PageContainer } from '@/components/containers/PageContainer/PageContainer';
import { FlexContainer } from '@/components/containers/FlexContainer/FlexContainer';
import Label from '@/components/form/Label/Label';

const Step = (props) => {
  const { number, title, description } = props;
  return (
    <FlexContainer
      className={
        'flex-column grayShadow16 border-rad8px padlr20 padtb20 align-items-center text-center format100 m-formatAuto'
      }
    >
      <span className={'bgPurple4 text-28 bold border-rad60px fontWhite padt14 padb6 width-50px'}>
        {number}
      </span>
      <Label type="Subtitle-1 bold padtb20">{title}</Label>
      <Label type="text-14">{description}</Label>
    </FlexContainer>
  );
};

const HowItWorks = (props) => {
  const { customTitle, buttonTitle, isShowButton } = props;
  return (
    <PageContainer>
      <FlexContainer
        className={'flex-column padt20 padb60 m-padb20 text-center'}
        justifyBetween
        alignItemsCenter
      >
        {customTitle && (
          <Label type="Subtitle-1 text-20 bold padtb40 m-padlr0">{customTitle}</Label>
        )}
        <FlexContainer className={'m-flex-column gap-20'} justifyBetween>
          <Step
            number={1}
            title={'Búsqueda'}
            description={
              'Encuentra el espacio perfecto para tus necesidades utilizando nuestros filtros de búsqueda avanzada'
            }
          />
          <Step
            number={2}
            title={'Agenda'}
            description={'Reserva tu espacio al instante con nuestro sencillo sistema de reservas'}
          />
          <Step
            number={3}
            title={'Trabaja'}
            description={'Disfruta de tu tiempo productivo en un espacio que se adapta a tu estilo'}
          />
        </FlexContainer>
        {isShowButton && (
          <FlexContainer className={'flex-column width100'} alignItemsCenter>
            <button
              className={
                'bgPurple4 bold border-rad8px borderGray050 fontWhite grayShadow16 margt50 margb20 padlr40 padtb10'
              }
            >
              {buttonTitle}
            </button>
          </FlexContainer>
        )}
      </FlexContainer>
    </PageContainer>
  );
};

export default HowItWorks;
