import { combineReducers } from 'redux';

const initialUserState = null;

const userReducer = (state = initialUserState, action) => {
  switch (action.type) {
    case 'SET_USER':
      return action.payload;
    case 'CLEAR_USER':
      return null;
    default:
      return state;
  }
};

const searchFormDataReducer = (state = initialUserState, action) => {
  switch (action.type) {
    case 'SET_SEARCH_FORM_DATA':
      return action.payload;
    case 'CLEAR_SEARCH_FORM_DATA':
      return null;
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  user: userReducer,
  searchFormData: searchFormDataReducer,
});

export default rootReducer;
