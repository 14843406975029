import React, { useState, useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { FlexContainer } from '@/components/containers/FlexContainer/FlexContainer';
import { TypeAnimation } from 'react-type-animation';
import GeneralSearchForm from '@/components/general/Forms/GeneralSearchForm/GeneralSearchForm';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import './HomeSlider.scss';

const fetchUserLocation = async () => {
  try {
    const response = await fetch('http://ip-api.com/json/');
    if (!response.ok) throw new Error(`Error: ${response.status}`);
    const data = await response.json();
    return { lat: data.lat, lng: data.lon };
  } catch (error) {
    console.error('Error fetching location:', error);
    return null;
  }
};

const AnimationTitle = () => {
  return (
    <TypeAnimation
      sequence={[
        '¿Dónde quieres trabajar hoy?',
        1000,
        'Encuentra el espacio ideal para tí',
        1000,
        '¿Dónde quieres trabajar hoy?',
        1000,
        'Encuentra el espacio ideal para tí',
        1000,
      ]}
      wrapper="h2"
      className={'m-text-20 sliderInfo-box-titles'}
      speed={20}
      style={{ display: 'inline-block' }}
      repeat={Infinity}
    />
  );
};

const SliderBox = (props) => {
  const { playHero, pauseHero, spacesData } = props;
  const [inputValue, setInputValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [userLocation, setUserLocation] = useState(null);
  const autocompleteService = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const [renderKey, setRenderKey] = useState(1);

  useEffect(() => {
    const initializeAutocomplete = () => {
      if (!autocompleteService.current && window.google) {
        autocompleteService.current = new window.google.maps.places.AutocompleteService();
      }
    };

    fetchUserLocation().then((location) => setUserLocation(location));

    if (!window.google) {
      window.addEventListener('load', initializeAutocomplete);
      return () => window.removeEventListener('load', initializeAutocomplete);
    } else {
      initializeAutocomplete();
    }
  }, []);

  const handleInputChange = (e) => {
    const searchValue = e.target.value;
    pauseHero();
    setInputValue(searchValue);
    setIsVisible(searchValue.length > 0);
    setRenderKey(renderKey + 1);

    if (searchValue.length > 3 && autocompleteService.current) {
      //if the number of requests to the API is exceeded, we will use the function that does NOT prioritize nearby locations
      if (userLocation) {
        autocompleteService.current.getPlacePredictions(
          {
            input: searchValue,
            location: new window.google.maps.LatLng(userLocation.lat, userLocation.lng),
            radius: 50000,
          },
          (predictions, status) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
              setSuggestions(predictions);
            }
          },
        );
      } else {
        autocompleteService.current.getPlacePredictions(
          { input: searchValue },
          (predictions, status) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
              setSuggestions(predictions);
            }
          },
        );
      }
    }

    if (window.scrollY < 300) {
      let pixelsToScroll = 300 - window.scrollY;
      window.scrollBy({
        top: pixelsToScroll,
        behavior: 'smooth',
      });
    }
  };

  const handleBlur = () => {
    setIsVisible(false);
    playHero();
  };
  const searchFormContainerStyle = 'dropdown-content';
  return (
    <FlexContainer className={'sliderInfo-box flex-column align-items-center'}>
      <FlexContainer column>
        <AnimationTitle />
      </FlexContainer>
      <FlexContainer className={'flex-column sliderInfo-box-input'}>
        <i className="fas fa-bars leftTextBox" />
        <input
          type="text"
          value={inputValue}
          className={
            'bgWhite grayShadow16 border-rad16px padtb20 text-16 text-center m-hide t-hide'
          }
          placeholder={'Busca aquí el espacio de tu preferencia'}
          onChange={handleInputChange}
          onFocus={() => setIsVisible(inputValue.length > 0)}
        />
        <input
          type="text"
          value={inputValue}
          className={'bgWhite grayShadow16 border-rad16px padtb20 text-16 text-center d-hide'}
          placeholder={'Busca aquí tu espacio'}
          onChange={handleInputChange}
          onFocus={() => setIsVisible(inputValue.length > 0)}
        />
        <i className="fas fa-search rightTextBox" />
      </FlexContainer>
      {isVisible && (
        <GeneralSearchForm
          spacesData={spacesData}
          inputValue={inputValue}
          className={searchFormContainerStyle}
          suggestions={suggestions}
          key={renderKey}
        />
      )}
    </FlexContainer>
  );
};

const HomeSlider = (props) => {
  const { spacesData } = props;
  const [heroSwiper, setSwiperRef] = useState(null);
  const playHero = () => {
    heroSwiper.autoplay.start();
  };
  const pauseHero = () => {
    heroSwiper.autoplay.stop();
  };
  return (
    <FlexContainer column className={'mySwiper items-align-center'}>
      <SliderBox playHero={playHero} pauseHero={pauseHero} spacesData={spacesData} />
      <Swiper
        onSwiper={setSwiperRef}
        navigation
        autoplay={{
          delay: 10500,
          disableOnInteraction: true,
        }}
        pagination={{
          clickable: true,
        }}
        modules={[Autoplay, Pagination, Navigation]}
        spaceBetween={0}
        slidesPerView={1}
      >
        <SwiperSlide>
          <img
            src="spaces/spaceslider.png"
            alt="Foto 1"
            style={{ width: '100%', height: '700px' }}
            className={''}
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src="spaces/spaceslider.png"
            alt="Foto 2"
            style={{ width: '100%', height: '700px' }}
            className={''}
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src="spaces/spaceslider.png"
            alt="Foto 3"
            style={{ width: '100%', height: '700px' }}
            className={''}
          />
        </SwiperSlide>
      </Swiper>
    </FlexContainer>
  );
};

export default HomeSlider;
