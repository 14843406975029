export const getOrdinal = () => {
  return {
    baseOrdinal: [
      '',
      'Primera',
      'Segunda',
      'Tercera',
      'Cuarta',
      'Quinta',
      'Sexta',
      'Séptima',
      'Octava',
      'Novena',
      'Décima',
      'Undécima',
      'Duodécima',
      'Decimotercera',
      'Decimocuarta',
      'Decimoquinta',
      'Decimosexta',
      'Decimoséptima',
      'Decimoctava',
      'Decimonovena',
      'Vigésima',
    ],
    tensOrdinal: [
      '',
      '',
      'Vigésima',
      'Trigésima',
      'Cuadragésima',
      'Quincuagésima',
      'Sexagésima',
      'Septuagésima',
      'Octogésima',
      'Nonagésima',
    ],
    getBaseOrdinal: function (num) {
      return this.baseOrdinal[num] || '';
    },
    getComplexOrdinal: function (num) {
      const tens = Math.floor(num / 10);
      const ones = num % 10;
      const tensText = this.tensOrdinal[tens] || '';
      const onesText = this.baseOrdinal[ones]?.toLowerCase() || '';
      return ones === 0 ? tensText : `${tensText}${onesText}`;
    },
    getOrdinal: function (n) {
      return n <= 20 ? this.getBaseOrdinal(n) : this.getComplexOrdinal(n);
    },
  };
};
