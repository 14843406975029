import React, { useEffect } from 'react';
import { useHome } from './hooks/Home.hook';
import HomeView from './views/HomeView';
import LoadingComponent from '@/components/general/Loading/LoadingComponent';

const HomeController = () => {
  const { isLoading, spacesDataFromBackend } = useHome();
  if (isLoading) {
    return <LoadingComponent loading={isLoading} />;
  } else {
    return <HomeView spacesDataFromBackend={spacesDataFromBackend} />;
  }
};

export default HomeController;
