import React, { useState, useEffect } from 'react';
import { useFetchAllSpacesSubtypeRelationships } from '@/services/spacesSubtypeRelationshipService';

export const useHome = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [spacesDataFromBackend, setSpacesDataFromBackend] = useState([]);

  const { data, error } = useFetchAllSpacesSubtypeRelationships();

  useEffect(() => {
    if (data) {
      setSpacesDataFromBackend(data);
      setIsLoading(false);
    } else if (error) {
      console.error('Error fetching spaces data:', error);
      setIsLoading(false);
    }
  }, [data, error]);

  return {
    isLoading,
    setIsLoading,
    spacesDataFromBackend,
  };
};
