import React, { useEffect } from 'react';
import { useProductPreList } from './hooks/ProductPreList.hook';
import { useNavigate } from 'react-router-dom';
import ProductPreListView from './views/ProductPreListView';
import LoadingComponent from '@/components/general/Loading/LoadingComponent';

const ProductPreListController = () => {
  const { isLoading, spacesDataFromBackend, searchFormData } = useProductPreList();
  const navigate = useNavigate();

  if (searchFormData) {
    if (isLoading) {
      return <LoadingComponent loading={isLoading} />;
    } else {
      const coordinates = searchFormData?.coordinates;

      const haversine = (lat1, lon1, lat2, lon2) => {
        const R = 6371;
        const dLat = ((lat2 - lat1) * Math.PI) / 180;
        const dLon = ((lon2 - lon1) * Math.PI) / 180;
        const a =
          Math.sin(dLat / 2) * Math.sin(dLat / 2) +
          Math.cos((lat1 * Math.PI) / 180) *
            Math.cos((lat2 * Math.PI) / 180) *
            Math.sin(dLon / 2) *
            Math.sin(dLon / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        return R * c;
      };

      const MAX_DISTANCE = 20;

      const filteredSpaces = spacesDataFromBackend
        ?.map((space) => {
          const distance = haversine(
            coordinates.lat,
            coordinates.lng,
            parseFloat(space?.space_latitude),
            parseFloat(space?.space_logitude),
          );
          return { ...space, distance };
        })
        ?.filter((space) => space?.distance <= MAX_DISTANCE)
        ?.filter(
          (space) => space?.subtype_title.toLowerCase() === searchFormData?.spaceType.toLowerCase(),
        )
        .sort((a, b) => a.distance - b.distance);
      return (
        <ProductPreListView
          spacesDataFromBackend={filteredSpaces}
          searchFormData={searchFormData}
        />
      );
    }
  } else {
    navigate('/');
  }
};

export default ProductPreListController;
