export const setUser = (user) => {
  console.log('Dispatching SET_USER with:', user);
  return {
    type: 'SET_USER',
    payload: user,
  };
};

export const clearUser = () => ({
  type: 'CLEAR_USER',
});
