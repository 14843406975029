import React, { useState, useEffect } from 'react';
import { FlexContainer } from '@/components/containers/FlexContainer/FlexContainer';
import ClipLoader from 'react-spinners/ClipLoader';
import './LoadingComponent.scss';

const LoadingComponent = (props) => {
  const { isLoading } = props;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <FlexContainer className={'flex-column'}>
      <FlexContainer
        className={'flex-column margt80 margb80 marglr80 m-marglr10 align-items-center'}
      >
        <FlexContainer className="flex-column skeleton-container">
          <FlexContainer className="skeleton skeleton-banner" />
        </FlexContainer>
        <FlexContainer className="skeleton-container">
          <FlexContainer className="skeleton skeleton-filter" />
          <FlexContainer className="skeleton skeleton-filter" />
          <FlexContainer className="skeleton skeleton-filter" />
          <FlexContainer className="skeleton skeleton-filter" />
          <FlexContainer className="skeleton skeleton-filter" />
        </FlexContainer>
        <FlexContainer className="skeleton-container gap-30 m-flex-column">
          <FlexContainer className="skeleton skeleton-card" />
          <FlexContainer className="skeleton skeleton-card" />
          <FlexContainer className="skeleton skeleton-card" />
          <FlexContainer className="skeleton skeleton-card" />
        </FlexContainer>
        <FlexContainer className="skeleton-container gap-30 m-flex-column">
          <FlexContainer className="skeleton skeleton-card" />
          <FlexContainer className="skeleton skeleton-card" />
          <FlexContainer className="skeleton skeleton-card" />
          <FlexContainer className="skeleton skeleton-card" />
        </FlexContainer>
      </FlexContainer>
    </FlexContainer>
  );
};

export default LoadingComponent;
