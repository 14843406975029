import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { FlexContainer } from '@/components/containers/FlexContainer/FlexContainer';
import { PaymentElement, Elements, useStripe, useElements } from '@stripe/react-stripe-js';
import Label from '@/components/form/Label/Label';

const apiUrl = process.env.REACT_APP_API_URL;

const CheckoutForm = (props) => {
  const { spaceDetail, formValues } = props;
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoadingCheckout, setIsLoadingCheckout] = useState(false);

  const handleSubmit = async (event) => {
    setIsLoadingCheckout(true);
    event.preventDefault();
    if (elements == null) {
      return;
    }

    const { error: submitError } = await elements.submit();
    if (submitError) {
      setErrorMessage(submitError.message);
      return;
    }

    const pricePerHour = spaceDetail?.hourly_rate;
    const hours = formValues?.endTime - formValues?.startTime;
    const totalPrice = (
      pricePerHour *
      (formValues?.guestQuantyty || 1) *
      (hours > 0 ? hours : 0)
    ).toFixed(2);

    const amount = Math.round(parseFloat(totalPrice) * 100);
    //const currency = 'usd';
    const currency = 'mxn';

    const res = await fetch(`${apiUrl}/POST/checkout`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        amount,
        currency,
      }),
    });

    const { client_secret } = await res.json();
    console.log('ver secret', client_secret);

    const { error } = await stripe.confirmPayment({
      elements,
      clientSecret: client_secret,
      confirmParams: {
        //return_url: 'http://localhost:3000/reservationSuccess',
        return_url: 'https://popnest.org/reservationSuccess',
      },
    });

    if (error) {
      setErrorMessage(error.message);
      setIsLoadingCheckout(false);
    } else {
      console.log('Pago exitoso');
      setIsLoadingCheckout(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      {!isLoadingCheckout && (
        <button
          type="submit"
          className={
            'bgPurple4 width100 bold border-rad8px borderGray050 fontWhite grayShadow16 margtb20 padlr40 padtb20 text-18'
          }
          disabled={!stripe || !elements}
        >
          Pagar aquí
        </button>
      )}
      {isLoadingCheckout && <Label className={'margtb10'}>Cargando...</Label>}
      {errorMessage && <div>{errorMessage}</div>}
    </form>
  );
};

const stripePromise = loadStripe(
  'pk_live_51MuKSIA0gqRxLoT3YHraiqMUanidK11hZZB8vptnBPNDPQUwEWNfKx8yMDbONCyPA5BHHjGryNJYEp2BZzX1m67k00ZbJdQDlT',
);
//const stripePromise = loadStripe('pk_test_6pRNASCoBOKtIshFeQd4XMUh');

const PaymentForm = (props) => {
  const { spaceDetail, formValues } = props;
  const pricePerHour = spaceDetail?.hourly_rate;
  const hours = formValues?.endTime - formValues?.startTime;
  const totalPrice = pricePerHour * formValues?.guestQuantyty * (hours > 0 ? hours : 0);
  const amount = parseFloat(totalPrice.toFixed(2));

  const options = {
    mode: 'payment',
    amount: Math.round(amount * 100),
    //currency: 'usd',
    currency: 'mxn',
    // Fully customizable with appearance API.
    appearance: {
      /*...*/
    },
  };
  return (
    <Elements stripe={stripePromise} options={options}>
      <FlexContainer className="padtb10 bgGray025 margt6" />
      <Label className={'margt10 text-16 m-text-12'}>
        Precio total a pagar: <span className={'bold'}>${totalPrice.toFixed(2)} MX</span>
      </Label>
      <CheckoutForm spaceDetail={spaceDetail} formValues={formValues} />
    </Elements>
  );
};

export default PaymentForm;
