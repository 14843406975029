import React from 'react';
import '@/components/form/Label/Label.scss';
import classnames from 'classnames';

function Label({ type, bold, color, inline, children, className, prefix, ...props }) {
  const labelClass = classnames({
    [type]: type,
    [color]: color,
    bold: bold,
    inlineBlock: inline,
    notBold: bold !== undefined && !bold,
    [className]: className,
  });
  return (
    <div {...props} className={labelClass}>
      {children}
    </div>
  );
}

export default Label;
