import React, { useEffect } from 'react';
import { useReservationSuccess } from './hooks/ReservationSuccess.hook';
import { useLocation } from 'react-router-dom';
import ReservationSuccessView from './views/ReservationSuccessView';
import LoadingComponent from '@/components/general/Loading/LoadingComponent';

const ReservationSuccessController = () => {
  const { isLoading, spacesData } = useReservationSuccess();
  const location = useLocation();
  const { spaceDetail, formValues } = location.state || {};
  if (isLoading) {
    return <LoadingComponent loading={isLoading} />;
  } else {
    return (
      <ReservationSuccessView
        spacesData={spacesData}
        spaceDetail={spaceDetail}
        formValues={formValues}
      />
    );
  }
};

export default ReservationSuccessController;
