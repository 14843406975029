import { useQuery, useMutation } from '@tanstack/react-query';

const apiUrl = process.env.REACT_APP_API_URL;

export const fetchAllUsers = async () => {
  const response = await fetch(`${apiUrl}/GET/users`);
  if (!response.ok) {
    throw new Error('Error fetching users');
  }
  return response.json();
};

export const fetchUserById = async id => {
  const response = await fetch(`${apiUrl}/GET/user/${id}`);
  if (!response.ok) {
    throw new Error('Error fetching user by ID');
  }
  return response.json();
};

export const createUser = async userData => {
  const response = await fetch(`${apiUrl}/POST/user`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(userData),
  });

  if (!response.ok) {
    throw new Error('Error creating user');
  }
  return response.json();
};

export const updateUserMainImage = async (userId, mainImageFile) => {
  const formData = new FormData();
  formData.append('main_image', mainImageFile);
  formData.append('user_id', userId);

  for (const [key, value] of formData.entries()) {
    console.log(key, value);
  }

  const response = await fetch(`${apiUrl}/POST/user/update_main_image`, {
    method: 'POST',
    body: formData,
  });

  if (!response.ok) {
    throw new Error('Error updating space main image');
  }
  return response.json();
};

export const updateUser = async ({ id, userData }) => {
  const response = await fetch(`${apiUrl}/PUT/user/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(userData),
  });
  if (!response.ok) {
    throw new Error('Error updating user');
  }
  return response.json();
};

export const deleteUser = async id => {
  const response = await fetch(`${apiUrl}/PUT/delete_user/${id}`, {
    method: 'PUT',
  });

  if (!response.ok) {
    throw new Error('Error deleting user');
  }
  return response.json();
};

export const deleteUsersSeveral = async userIds => {
  const response = await fetch(`${apiUrl}/PUT/delete_user_several`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ userIds }),
  });

  if (!response.ok) {
    throw new Error('Error deleting multiple users');
  }
  return response.json();
};

export const enabledUsersSeveral = async userIds => {
  const response = await fetch(`${apiUrl}/PUT/enabled_user_several`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ userIds }),
  });

  if (!response.ok) {
    throw new Error('Error enabling multiple users');
  }
  return response.json();
};

export const disabledUsersSeveral = async userIds => {
  const response = await fetch(`${apiUrl}/PUT/disabled_user_several`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ userIds }),
  });

  if (!response.ok) {
    throw new Error('Error disabling multiple users');
  }
  return response.json();
};

export const useFetchAllUsers = () => {
  return useQuery({
    queryKey: ['users'],
    queryFn: fetchAllUsers,
  });
};

export const useFetchUserById = id => {
  return useQuery({
    queryKey: ['user', id],
    queryFn: () => fetchUserById(id),
    enabled: !!id,
  });
};

export const useCreateUser = () => {
  return useMutation({
    mutationFn: createUser,
    onSuccess: () => {
      console.log('User created successfully');
    },
    onError: error => {
      console.error('Error creating user:', error);
    },
  });
};

export const useUpdateUserMainImage = () => {
  return useMutation({
    mutationFn: data => updateUserMainImage(data.userId, data.mainImageFile),
    onSuccess: () => {
      console.log('Data', this.data);
      console.log('Space main image updated successfully');
    },
    onError: error => {
      console.error('Error updating space main image:', error);
    },
  });
};

export const useUpdateUser = () => {
  return useMutation({
    mutationFn: updateUser,
    onSuccess: () => {
      console.log('User updated successfully');
    },
    onError: error => {
      console.error('Error updating user:', error);
    },
  });
};

export const useDeleteUser = () => {
  return useMutation({
    mutationFn: deleteUser,
    onSuccess: () => {
      console.log('User deleted successfully');
    },
    onError: error => {
      console.error('Error deleting user:', error);
    },
  });
};

export const useDeleteUsersSeveral = () => {
  return useMutation({
    mutationFn: deleteUsersSeveral,
    onSuccess: () => {
      console.log('Multiple users deleted successfully');
    },
    onError: error => {
      console.error('Error deleting multiple users:', error);
    },
  });
};

export const useEnabledUsersSeveral = () => {
  return useMutation({
    mutationFn: enabledUsersSeveral,
    onSuccess: () => {
      console.log('Multiple users enabled successfully');
    },
    onError: error => {
      console.error('Error enabling multiple users:', error);
    },
  });
};

export const useDisabledUsersSeveral = () => {
  return useMutation({
    mutationFn: disabledUsersSeveral,
    onSuccess: () => {
      console.log('Multiple users disabled successfully');
    },
    onError: error => {
      console.error('Error disabling multiple users:', error);
    },
  });
};
