import React, { useState, useEffect } from 'react';
import { FlexContainer } from '@/components/containers/FlexContainer/FlexContainer';
import { GoogleMap, Marker, useJsApiLoader, Circle } from '@react-google-maps/api';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper/modules';
import { useNavigate } from 'react-router-dom';
import { CommentSection } from 'react-comments-section';
import { PageContainer } from '@/components/containers/PageContainer/PageContainer';
import Rating from 'rating-react';
import SpaceCard from '@/components/reusable/SpaceCard/SpaceCard';
import SearchAccordion from '@/components/general/SearchAccordion/SearchAccordion';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Label from '@/components/form/Label/Label';
import classNames from 'classnames';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';
import './ProductPreListView.scss';
import 'react-comments-section/dist/index.css';

const GoogleMapComponent = (props) => {
  const { center, markers, detail } = props;
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyC75q_cO4SIMGhQgRJwAIpNfNQ5cXwVXnU',
  });

  console.log('hola pruebas');

  const [map, setMap] = React.useState(null);

  const containerStyle = {
    width: '100%',
    height: '100%',
  };

  const mapStyles = [
    {
      featureType: 'all',
      elementType: 'geometry',
      stylers: [{ saturation: -30 }],
    },
    {
      featureType: 'landscape',
      elementType: 'geometry',
      stylers: [{ color: '#f5f3e5' }],
    },
    {
      featureType: 'road',
      elementType: 'geometry',
      stylers: [{ color: '#e0e0e0' }],
    },
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [{ color: '#d9e3f0' }],
    },
    {
      featureType: 'poi.park',
      elementType: 'geometry',
      stylers: [{ color: '#e5e9d3' }],
    },
    {
      featureType: 'poi',
      elementType: 'labels.icon',
      stylers: [{ color: '#6c75f0' }],
    },
    {
      featureType: 'all',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#6c75f0' }],
    },
    {
      featureType: 'administrative.locality',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#808080' }],
    },
    {
      featureType: 'administrative.province',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#808080' }],
    },
  ];

  const onLoad = React.useCallback(function callback(map) {
    //const bounds = new window.google.maps.LatLngBounds(center);
    //map.fitBounds(bounds);

    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const customIcon = {
    url: markers ? markers[0]?.image : '',
    scaledSize: new window.google.maps.Size(60, 60),
    origin: new window.google.maps.Point(0, 0),
    anchor: new window.google.maps.Point(30, 30),
  };

  console.log('er ion', customIcon);
  console.log('er ion2', markers);

  const isPreListMapReady = markers && !detail;
  const isReadyToMap = isLoaded && markers;

  return isReadyToMap ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={13}
      onLoad={onLoad}
      onUnmount={onUnmount}
      options={{
        styles: mapStyles,
      }}
    >
      {isPreListMapReady &&
        markers
          .map((space) => ({
            lat: parseFloat(space.space_latitude.trim()),
            lng: parseFloat(space.space_logitude.trim()),
          }))
          .filter((space) => !isNaN(space.lat) && !isNaN(space.lng))
          .map((space, index) => (
            <Marker
              key={index}
              position={{ lat: space.lat, lng: space.lng }}
              onClick={() => console.log(`Clicked marker at ${space.lat}, ${space.lng}`)}
            />
          ))}
      {detail && (
        <>
          {/* Renderizar el marcador */}
          <Marker
            key={1}
            position={{ lat: markers[0]?.lat, lng: markers[0]?.lng }}
            icon={customIcon}
            onClick={() => console.log(`Clicked marker at ${markers[0]?.lat}, ${markers[0]?.lng}`)}
          />

          {/* Renderizar el círculo alrededor del marcador */}
          <Circle
            center={{ lat: markers[0]?.lat, lng: markers[0]?.lng }}
            radius={500} // Ajusta el radio en metros
            options={{
              strokeColor: '#FF0000',
              strokeOpacity: 0.8,
              strokeWeight: 2,
              fillColor: '#FF0000',
              fillOpacity: 0.35,
            }}
          />
        </>
      )}
    </GoogleMap>
  ) : (
    <></>
  );
};

const SpaceDetailInfo = (props) => {
  const { closeButton, spaceData } = props;
  const data = [
    /*
    {
      userId: '01a',
      comId: '012',
      fullName: 'Riya Negi',
      avatarUrl: 'https://ui-avatars.com/api/name=Riya&background=random',
      userProfile: 'https://www.linkedin.com/in/riya-negi-8879631a9/',
      text: 'Hey, Loved your blog! ',
      timestamp: '2024-09-28T10:34:56Z',
      replies: [
        {
          userId: '02a',
          comId: '013',
          userProfile: 'https://www.linkedin.com/in/riya-negi-8879631a9/',
          fullName: 'Adam Scott',
          avatarUrl: 'https://ui-avatars.com/api/name=Adam&background=random',
          text: 'Thanks! It took me 1 month to finish this project but I am glad it helped out someone!🥰',
          timestamp: '2024-09-28T12:34:56Z',
        },
        {
          userId: '01a',
          comId: '014',
          userProfile: 'https://www.linkedin.com/in/riya-negi-8879631a9/',
          fullName: 'Riya Negi',
          avatarUrl: 'https://ui-avatars.com/api/name=Riya&background=random',
          text: 'thanks!😊',
          timestamp: '2024-09-28T12:34:56Z',
        },
      ],
    },
    {
      userId: '02b',
      comId: '017',
      fullName: 'Lily',
      userProfile: 'https://www.linkedin.com/in/riya-negi-8879631a9/',
      text: 'I have a doubt about the 4th point🤔',
      avatarUrl: 'https://ui-avatars.com/api/name=Lily&background=random',
      timestamp: '2024-09-28T12:34:56Z',
      replies: [],
    },
    */
  ];
  const navigate = useNavigate();
  const goToSpaceDetails = () => {
    navigate('/productDetails', { state: { spaceData } });
  };
  const coffee = spaceData?.coffe_water === '1' ? true : false;
  const parking = spaceData?.parking === '1' ? true : false;

  const imagesArray = (() => {
    try {
      const parsed = JSON.parse(spaceData?.main_image);
      return Array.isArray(parsed) && parsed.length > 0 ? parsed : [spaceData?.main_image];
    } catch {
      return [spaceData?.main_image];
    }
  })();

  const center = {
    lat: parseFloat(spaceData?.space_latitude.trim()),
    lng: parseFloat(spaceData?.space_logitude.trim()),
    image: imagesArray[0],
  };

  const markers = [center];

  if (spaceData) {
    return (
      <FlexContainer className={'flex-column productPreList'}>
        <FlexContainer className={'width100 margt10'}>
          <Label type="Subtitle-1 width100 bold padt10 text-20 m-text-14">
            {spaceData?.space_title}
          </Label>
          <FlexContainer className={'flex-column items-align-end margt10 width100'}>
            <button
              onClick={closeButton}
              className={
                'bgWhite bold border-rad8px borderGray050 grayShadow16 padlr20 padtb10 grayN250'
              }
            >
              Atras <i className="fas fa-arrow-left" />
            </button>
          </FlexContainer>
        </FlexContainer>
        <FlexContainer className={'width100 margtb10 productPreList d-hide'}>
          <Swiper
            navigation
            autoplay={{
              delay: 10500,
              disableOnInteraction: true,
            }}
            modules={[Autoplay, Navigation]}
            spaceBetween={30}
            slidesPerView={1}
            className=""
          >
            {imagesArray.map((image, index) => (
              <SwiperSlide key={index}>
                <img src={image} alt={spaceData?.space_title} className={'width100'} />
              </SwiperSlide>
            ))}
          </Swiper>
        </FlexContainer>
        <FlexContainer className={'width100 margtb20 m-hide'}>
          <img src={imagesArray[0]} alt={spaceData?.space_title} className={'width100'} />
        </FlexContainer>
        <FlexContainer className={'width100 margt10'}>
          <FlexContainer
            className={'items-align-start width100 align-items-center fontGray075 gap-10'}
          >
            {<i className={'fas fa-wifi text-20'} />}
            {coffee && <i className={'fas fa-coffee text-20'} />}
            {parking && <i className={'fas fa-car-side text-20'} />}
          </FlexContainer>
          <FlexContainer className={'flex-column items-align-end width100'}>
            <button
              onClick={goToSpaceDetails}
              className={
                'bgPurple4 fontWhite bold border-rad8px borderGray050 grayShadow16 padlr20 padtb10 grayN250'
              }
            >
              Reservar
            </button>
          </FlexContainer>
        </FlexContainer>
        <Label type="text-14" className={'width100 margtb20 text-justify'}>
          <div dangerouslySetInnerHTML={{ __html: spaceData?.space_details_general }} />
        </Label>
        <Label type="Subtitle-1" className={'text-14 m-text-16 text-left flex-column'}>
          Ubicación del espacio (dirección completa al efectuar la reservación)
        </Label>
        <Label type="text-14" className={'width100 margtb20 text-justify'}>
          <div
            dangerouslySetInnerHTML={{
              __html: spaceData?.space_address?.replace(/\d+/g, '****'),
            }}
          />
        </Label>
        <FlexContainer className={'width100 margt10 height-500px'}>
          <GoogleMapComponent center={center} markers={markers} detail key={2} />
        </FlexContainer>
        <FlexContainer className={'flex-column width100 margt10'}>
          <Accordion expanded>
            <AccordionSummary
              expandIcon={<ArrowDropDownIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              <Label type="Subtitle-1" className={'text-14 m-text-16 text-left flex-column'}>
                Coworking
              </Label>
            </AccordionSummary>
            <AccordionDetails>
              <FlexContainer className={'flex-column '}>
                <Label type="text-14" className={'width100 margtb20 text-justify'}>
                  <div dangerouslySetInnerHTML={{ __html: spaceData?.space_details }} />
                </Label>
                <Rating value={4} size={'sm'} />
                <button
                  type="submit"
                  onClick={goToSpaceDetails}
                  className={
                    'bgPurple4 bold border-rad8px borderGray050 fontWhite grayShadow16 margtb20 padlr40 padtb20 text-14'
                  }
                >
                  Reserva tu coworking aquí
                </button>
              </FlexContainer>
            </AccordionDetails>
          </Accordion>
          <Accordion className="margt10">
            <AccordionSummary
              expandIcon={<ArrowDropDownIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              <Label type="Subtitle-1" className={'text-14 m-text-16 text-left flex-column'}>
                Sala de Juntas
              </Label>
            </AccordionSummary>
            <AccordionDetails>
              <FlexContainer className={'flex-column '}>
                <Label type="text-14" className={'width100 margtb20 text-justify'}>
                  <div dangerouslySetInnerHTML={{ __html: spaceData?.space_details }} />
                </Label>
                <Rating value={4} size={'sm'} />
                <button
                  type="submit"
                  onClick={goToSpaceDetails}
                  className={
                    'bgPurple4 bold border-rad8px borderGray050 fontWhite grayShadow16 margtb20 padlr40 padtb20 text-14'
                  }
                >
                  Reserva tu sala de juntas aquí
                </button>
              </FlexContainer>
            </AccordionDetails>
          </Accordion>
        </FlexContainer>
        <CommentSection
          currentUser={{
            currentUserId: '01a',
            currentUserImg: 'https://ui-avatars.com/api/name=Riya&background=random',
            currentUserProfile: 'https://www.linkedin.com/in/riya-negi-8879631a9/',
            currentUserFullName: 'Riya Negi',
          }}
          commentData={data}
          logIn={{
            onLogin: () => alert('Call login function '),
            signUpLink: 'http://localhost:3001/',
          }}
          placeHolder="Write your comment..."
          onSubmitAction={({}) => console.log('check submit, ', data)}
          currentData={(data) => {
            console.log('current data', data);
          }}
        />
      </FlexContainer>
    );
  } else {
    return <></>;
  }
};

const NotFoundComponent = (props) => {
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1);
  };
  return (
    <FlexContainer className={'productPreList margtb100 width100 justify-between'}>
      <Label className={'text-20'}>
        No se encontraron resultados para la búsqueda seleccionada. Inténtelo nuevamente.
      </Label>
      <button
        type="button"
        className={'bgGray050 border-rad4px borderGray050 fontGray500 text-16'}
        onClick={handleBack}
      >
        <span className={'padlr10'} style={{ display: 'inline-flex', gap: '6px' }}>
          <i className={'fa fa-arrow-left margr6'} aria-hidden="true" /> Atrás
        </span>
      </button>
    </FlexContainer>
  );
};

const ProductPreListView = (props) => {
  const { spacesDataFromBackend, searchFormData } = props;
  const [spaceDetailData, setSpaceDetailData] = useState(null);
  const [accordionKey, setAccordionKey] = useState(1);
  const isShowGeneralMap = !spaceDetailData && spacesDataFromBackend.length >= 1;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    console.log('searchFormData cambió:', searchFormData);
    window.scrollTo(0, 0);
    setAccordionKey(accordionKey + 1);
  }, [searchFormData]);

  const coordinates = searchFormData?.coordinates;

  const openSpaceDetail = (space) => {
    console.log(JSON.stringify(space));
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    setSpaceDetailData(space);
  };
  const closeSpaceDetail = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    setSpaceDetailData(null);
  };

  const containerLeftClass = classNames('flex-column margtb70', {
    format60: spaceDetailData,
    width100: !spaceDetailData,
    't-hide m-hide': spaceDetailData,
  });
  const containerRightClass = classNames(
    'flex-column margtb70 padtb20 padlr20 m-padlr0 t-hide m-hide width100',
    {
      format120: spaceDetailData,
      format100: !spaceDetailData,
    },
  );
  const center = {
    lat: coordinates?.lat,
    lng: coordinates?.lng,
  };
  return (
    <FlexContainer className={'flex-column width100'}>
      <SearchAccordion className={'margt70 width100'} key={accordionKey} />
      <PageContainer className={'margt40'}>
        <FlexContainer className={'m-flex-column padlr40 m-padlr10'}>
          {spaceDetailData && (
            <FlexContainer className={'flex-column margtb70 padlr20 m-padlr0 d-hide'}>
              <SpaceDetailInfo closeButton={closeSpaceDetail} spaceData={spaceDetailData} />
            </FlexContainer>
          )}
          {spacesDataFromBackend.length === 0 && <NotFoundComponent />}
          {spacesDataFromBackend.length !== 0 && (
            <>
              <FlexContainer className={containerLeftClass}>
                {spacesDataFromBackend?.length >= 1 &&
                  spacesDataFromBackend.map((space, index) => (
                    <SpaceCard
                      spaceInfo={space}
                      isInlineStyle
                      imgUrl={space?.main_image}
                      spaceType={space?.type}
                      spaceTitle={space?.space_title}
                      spaceDescription={space?.space_details}
                      price={space?.price}
                      handleDetailButton={() => openSpaceDetail(space)}
                      priceAndCapacityDetails
                      wifi
                      coffee
                      parking
                      isWhiteBg
                      isMobileStyle
                    />
                  ))}
              </FlexContainer>
              <FlexContainer className={containerRightClass}>
                {isShowGeneralMap && (
                  <GoogleMapComponent center={center} markers={spacesDataFromBackend} key={1} />
                )}
                {spaceDetailData && (
                  <SpaceDetailInfo closeButton={closeSpaceDetail} spaceData={spaceDetailData} />
                )}
              </FlexContainer>
            </>
          )}
        </FlexContainer>
      </PageContainer>
    </FlexContainer>
  );
};

export default ProductPreListView;
