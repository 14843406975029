import React, { useState, useEffect } from 'react';
import ParadiseMudNavbar from 'paradise-mud-navbar';
import Modal from 'react-modal';
import GeneralLoginForm from '@/components/general/Forms/GeneralLoginForm/GeneralLoginForm';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { FlexContainer } from '@/components/containers/FlexContainer/FlexContainer';
import { useNavigate } from 'react-router-dom';
import 'paradise-mud-navbar/src/components/ParadiseMudNavbar.css';
import './PersonalizedHeaderStyles.scss';

//Modal.setAppElement('#yourAppElement');

const Header = (props) => {
  const {} = props;
  const [scrollPosition, setScrollPosition] = useState(0);
  const [modalIsOpen, setIsOpen] = React.useState(false);

  let subtitle;

  const customStyles = {
    overlay: {
      position: 'fixed',
      zIndex: 3,
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(255, 255, 255, 0.75)',
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  const location = useLocation();
  const effectOnScroll = location.pathname === '/';

  const navigate = useNavigate();

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  const hideMobileMenu = () => {
    const modal = document.querySelector('.paradise-mud-mobile-navbar-modal.visible');
    if (modal) {
      modal.classList.remove('visible');
    }
  };

  const goToHome = () => {
    hideMobileMenu();
    navigate('/');
  };

  const goToAbout = () => {
    hideMobileMenu();
    navigate('/about');
  };

  const goToSpaces = () => {
    hideMobileMenu();
    navigate('/spaces');
  };

  const goToYourSpaces = () => {
    hideMobileMenu();
    navigate('/yourSpaces');
  };

  const openModal = () => {
    hideMobileMenu();
    setIsOpen(true);
  };

  const afterOpenModal = () => {
    subtitle.style.color = '#f00';
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const navBarStyleConditions = scrollPosition > 280 || !effectOnScroll;
  const className = navBarStyleConditions ? '' : 'transparent-navbar';
  const navbarMainLogo = navBarStyleConditions ? '/logo2.png' : '/logo.png';
  const user = useSelector((state) => state.user);

  return (
    <FlexContainer className={'flex-column myMainNavBar'}>
      <ParadiseMudNavbar
        navbarClassNames={[className]}
        navbarLogos={[navbarMainLogo]}
        navbarLinks={[
          { title: 'Inicio', onClick: goToHome },
          { title: 'Acerca de Popnest', onClick: goToAbout },
          { title: 'Espacios', onClick: goToSpaces },
          { title: 'Publica tu espacio', onClick: goToYourSpaces },
          { title: user ? 'Hola ' + user?.name : 'Iniciar Sesión', onClick: openModal },
        ]}
        linksPosition={'right'}
      />
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <h2 ref={(_subtitle) => (subtitle = _subtitle)}></h2>
        <button className={'fright'} onClick={closeModal}>
          x
        </button>
        <GeneralLoginForm inputValue={''} className={'marglr40 m-marglr20 margt40'} />
      </Modal>
    </FlexContainer>
  );
};

export default Header;
